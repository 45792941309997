import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Wine } from 'src/app/shared/models/wine.model';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit{
  @Input() item: Wine;
  @Input() counter: number;
  @Output() readonly selected = new EventEmitter();

  ngOnInit(): void {
  }
  

  isModalVisible: boolean;

  select(): void {
    this.isModalVisible = true;
  }

  close(): void {
    this.isModalVisible = false;
  }

 
}
