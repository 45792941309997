import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  styles: [
    `:root {
      --first-color: primaryColor;
      --second-color: secondaryColor;
      --third-color: tertiaryColor;
    }`
  ]
})
export class HomeComponent implements OnInit {

  links = [
    { title: 'Explore Wines', subtitle: 'Wines by Flavor', link: '/explore' },
    { title: 'Browse Wines', subtitle: '', link: '/browse' },
    { title: 'Browse Wines', subtitle: '', link: '/wineries' },
    { title: 'View', subtitle: '', link: '/fullmenu' },
    { title: 'Discover', subtitle: 'Rodeo Uncorked! &reg;', link: '/discover' },
  ];

  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  colors:any = [];
  loading:boolean = false;
  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgBgMain = 'bg-main.png' +'?t='+ new Date().getTime();
  choice: string;
  choices: string;
  type: string;
  asyncResult: any;

  constructor(
    private router: Router,
    private api: AppService
    ) {}

  ngOnInit(): void {
    this.urlLogos = this.api.urlImages;
    this.changeWords();
  }


  async changeWords() {
    this.loading = true;
    this.asyncResult = await this.api.getType().toPromise();

    let shopType = this.asyncResult.site_type;

    switch (shopType) {
      case "cider":
        this.choice = "Cider";
        this.choices = "Ciders";
        this.links[0].subtitle = `${this.choices} by flavor`;
        this.links[1].subtitle = `${this.choices} by style`;
        this.getTheme();
      break;

      case "wine":
        this.choice = "wine";
        this.choices = "wines";

        this.links[0].subtitle = `by Flavor`;
        this.links[1].subtitle = `by Style`;
        this.links[2].subtitle = `by Winery`;
        this.links[3].subtitle = `Full Menu`;
        this.getTheme();
      break;
    
      default:
        break;
    }

  }

  getTheme() {
    this.loading = true;
    this.api.getTheme()
      .subscribe(data => {
        this.colors = data;
      
        this.primaryColor = this.colors[0].value;
        this.secondaryColor = this.colors[1].value;
        this.tertiaryColor = this.colors[2].value;

        document.documentElement.style.setProperty('--first-color', this.primaryColor);
        document.documentElement.style.setProperty('--second-color', this.secondaryColor);
        document.documentElement.style.setProperty('--third-color', this.tertiaryColor);
        this.loading = false;
      })
  }
  
  goTo(path: string): void {
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

}
