<div class="page content">
    <app-mobile-header title="Discover" subtitle="Rodeo Uncorked! &reg;"></app-mobile-header>

    <div class="d-flex flex-column flex-sm-row">
        <div class="sidebar-wrapper m-hidden">
            <app-sidebar activePage="Discover" (selected)="onSelectedSidebarOption($event)"></app-sidebar>
        </div>

        <section class="content-inner">
            <app-header class="m-hidden" title="Discover" subtitle="Rodeo Uncorked! &reg;"></app-header>
            <div>

                <div class="discover__name">About Rodeo Uncorked! &reg; </div>
                <div class="discover__text">
                    The Houston Livestock Show and Rodeo™ promotes agriculture by hosting an annual, family-friendly
                    experience that educates and entertains the public, supports Texas youth, showcases Western heritage
                    and provides year-round educational support within the community. Since its beginning in 1932, the
                    Rodeo has committed more than $600 million to the youth of Texas.
                </div>

                <div class="discover__text">
                    In 2004, the Rodeo Uncorked! &reg; program was founded to further support our agricultural mission
                    through viticulture. Rodeo Uncorked! celebrates wines from around the world and in our great State
                    of Texas to raise awareness of these wines, promote healthy competition among international and U.S.
                    producers, drive continuing improvements in
                    the quality of all wine, and introduce regional wine aficionados and professionals to the
                    educational and charitable aspects of the Houston Livestock Show and Rodeo.
                </div>

                <div class="discover__name">International Wine Competition </div>
                <div class="discover__text">
                    The Rodeo Uncorked! International Wine Competition, one of the largest wine competitions in America,
                    is judged by local and national experts with extensive wine credentials. The 2024 competition
                    included 2,985 wines from 22 countries, including France, Spain, Italy, Israel, Argentina and South
                    Africa. Texas wineries represented 539 entries, and 161 entries came from this year’s feature
                    region, Chile. A complete list of winners can be found at <a class="discover__link"
                        href="https://rodeohouston.com/wine" target="_blank">rodeohouston.com/wine</a>. These winners
                    are the highlight of the following annual events.
                </div>


                <div class="discover__name">Roundup & Best Bites Competition </div>
                <div class="discover__text">
                    Rodeo Uncorked! kicks off the Rodeo season with one of the largest gourmet food events in the world
                    with nearly 500 award-winning wines and signature dishes from over 100 of the area’s top restaurants
                    and culinary institutions. This is a top destination for wine enthusiasts, food writers and foodies
                    alike. Guests help decide which restaurant will take home the “People’s Choice Award” from the
                    competition. Tickets go on sale in early fall each year. The 2024 Rodeo Uncorked! Roundup & Best
                    Bites event was held Feb. 18, 2024.
                </div>

                <div class="discover__name">Champion Wine Auction & Dinner </div>
                <div class="discover__text">
                    The Houston Livestock Show and Rodeo hosts one of the leading charitable wine auctions in the
                    country, featuring nearly 500 award-winning wines. Guests enjoy a five-star dinner and cocktail
                    service while bidding on elegant items and experiences in our Silent Auction. After dinner, the
                    exciting live auction takes place, with the Grand
                    Champion, Reserve Grand Champion and other award-winning wines are showcased in carefully crafted
                    lots. The 2024 Rodeo Uncorked! Champion Wine Auction & Dinner was held Feb. 25, 2024.
                </div>

                <div class="discover__name">Rodeo Uncorked! Collector’s Cases </div>
                <div class="discover__text">
                    Rodeo Uncorked! offers an annual selection of 12 award-winning wines from the most distinct and
                    popular categories of the annual International Wine Competition in a commemorative, branded wooden
                    box for sale.
                </div>

                <div class="discover__name">Champion Wine Garden, presented by Frost Bank </div>
                <div class="discover__text">
                    Stop by this oasis at the Houston Livestock Show and Rodeo before or after RODEOHOUSTON. The
                    Champion Wine Garden, presented by Frost Bank, offers a cheerful atmosphere, top-notch entertainment
                    from rising Texas stars and a chance to savor more than 75 award-winning wines. Table and tent
                    reservations are available for
                    those looking to host an event in this popular spot during the Rodeo.
                </div>

                <div class="discover__name">Wine Educational Seminars </div>
                <div class="discover__text">
                    Sip, smell and learn through a series of seminars that will introduce you to some of the most loved
                    wines from the 2024 competition. These seminars are great for groups and
                    are hosted in the Champion Wine Garden during the Houston Livestock Show and Rodeo. Prices range
                    from $55 to $110 per person, and you must be 21+ to participate.
                </div>

                <div class="discover__name">Contact </div>
                <div class="discover__text">
                    For more information, please visit us at <a class="discover__link"
                        href="https://rodeohouston.com/wine" target="_blank">rodeohouston.com/wine</a>. Also, join our
                    Rodeo Uncorked! group on Facebook to stay up-to-date on all events.
                </div>

                <div class="discover__text">
                    The Wine Show office can be reached at <a class="discover__link" href="mailto:wine@rodeohouston.com"
                        target="_blank">wine@rodeohouston.com</a> or 832.667.1184 for additional inquiries.
                </div>

            </div>


            <!--div>
                <div class="discover__name">About Rodeo Uncorked! &reg;</div>
                <div class="discover__text">
                    The Houston Livestock Show and Rodeo™ promotes agriculture by hosting an annual, family-friendly experience that educates and entertains the public, supports Texas youth, showcases Western heritage and provides year-round educational support within the
                    community. Since its beginning in 1932, the Rodeo has committed more than $550 million to the youth of Texas.
                </div>
                <div class="discover__text">
                    In 2004, the Rodeo Uncorked!® program was founded to further support our agricultural mission through viticulture. Rodeo Uncorked! celebrates wines from around the world and in our great State of Texas to raise awareness of these wines, to promote healthy
                    competition among international and U.S. producers, to drive continuing improvements in the quality of all wine, and to introduce regional wine aficionados and professionals to the educational and charitable aspects of the Houston
                    Livestock Show and Rodeo.
                </div>

                <div class="discover__name">International Wine Competition</div>
                <div class="discover__text">
                    The Rodeo Uncorked! International Wine Competition is one of the largest in America, judged by local and national experts with extensive wine credentials. This 2022 competition,  in honor of Charles M. “Bear” Dalton, included 2,862 wines from 17 countries, including France, Spain, Italy, Israel, Peru, Argentina and South Africa. Texas wineries represented 480 entries, and 203 entries came from this year’s feature region, Washington State. A complete list of winners can be found at
                    <a class="discover__link" href="https://rodeohouston.com/wine" target="_blank">rodeohouston.com/wine</a>. These winners are the highlight of the following annual events.
                </div>

                <div class="discover__name">Roundup & Best Bites Competition</div>
                <div class="discover__text">
                    Rodeo Uncorked! kicks off the Rodeo season with one of the largest gourmet food events in the world with more than 450 award-winning wines and signature dishes from over 100 of the area’s top restaurants and culinary institutions. This is a top destination
                    for wine enthusiasts, food writers and foodies alike. Guests help decide which restaurant will take home the “People’s Choice Award” from the competition. Tickets go on sale in early fall each year. The 2020 Rodeo Uncorked! Roundup
                    & Best Bites event was held Feb. 23, 2020.
                </div>

                <div class="discover__name">Champion Wine Auction & Dinner</div>
                <div class="discover__text">
                    The Houston Livestock Show and Rodeo hosts one of the leading charitable wine auctions in the country, featuring nearly award-winning wines. While supporting one of the state’s strongest charities, guests enjoy a five-star dinner and cocktail service
                    and bid on elegant items and experiences in our Silent Auction. After dinner, the exciting live auction takes place, with the Grand Champion, Reserve Grand Champion and other award-winning wines are showcased in carefully crafted lots.
                    The 2022 Rodeo Uncorked! Champion Wine Auction & Dinner was held Feb. 27, 2022.
                </div>

                <div class="discover__name">Double Barrel Sampler Cases</div>
                <div class="discover__text">
                    Rodeo Uncorked! offers for sale an annual selection of 12 award-winning wines from the most distinct and popular categories of the annual International Wine Competition in a commemorative, branded wooden box.
                </div>

                <div class="discover__name">Champion Wine Garden, presented by Frost Bank</div>
                <div class="discover__text">
                    Stop by this oasis at the Houston Livestock Show and Rodeo before or after the Rodeo and concert. The Champion Wine Garden offers a cheerful atmosphere, top notch entertainment from rising Texas stars and a chance to savor more than 70 award-winning wines.
                    Table and tent reservations are available for those looking to host an event in this popular spot during the Show.
                </div>

                <div class="discover__name">Wine Educational Seminars</div>
                <div class="discover__text">
                    Sip, smell and learn through a series of seminars that will introduce you to some of the best loved wines from the 2022 competition. 
                    These seminars are great for groups and are hosted in the Champion Wine Garden during the Houston Livestock Show and Rodeo. Prices range from $55 to $95 per person, and you must be 21+ to participate.
                </div>

                <div class="discover__name">Contact</div>
                <div class="discover__text">
                    For more information, please visit us at
                    <a class="discover__link" href="https://rodeohouston.com/wine" target="_blank">rodeohouston.com/wine</a>. Also, join our Rodeo Uncorked! group on Facebook to stay current on all events.
                </div>
                <div class="discover__text">
                    The Wine Show office can be reached at
                    <a class="discover__link" href="mailto:wine@rodeohouston.com" target="_blank">wine@rodeohouston.com</a> or 832.667.1184 for additional inquiries.
                </div>
            </div-->
        </section>
    </div>

</div>