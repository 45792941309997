import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Wine } from '../shared/models/wine.model';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { createUrlResolverWithoutPackagePrefix, ThisReceiver } from '@angular/compiler';


@Component({
  selector: 'app-wineries',
  templateUrl: './wineries.component.html',
  styleUrls: ['./wineries.component.scss']
})
export class WineriesComponent implements OnInit {

  isMenuOpen: boolean;
  noResults: boolean = false;
  noResultsText: string = 'Your search has no results, please try another search';

  loading: boolean = false;

  wines: Array<Wine> = [];
  types: Array<any> = [];
  wineriesList: Array<any> = [];
  wineries: Array<any> = [
   
  ];
  // wineries: Array<any> = [
  //   {
  //     "winery": "Fox Run Vineyards",
  //     "winery_id": 1
  //   },
  //   {
  //     "winery": "Troon Vineyards",
  //     "winery_id": 2
  //   },
  //   {
  //     "winery": "Del Dotto Vineyards",
  //     "winery_id": 3
  //   },
  //   {
  //     "winery": "Whitehall Lane Winery",
  //     "winery_id": 4
  //   },
  //   {
  //     "winery": "Lakewood Vineyards",
  //     "winery_id": 5
  //   },
  //   {
  //     "winery": "Tres Sabores Winery",
  //     "winery_id": 6
  //   },
  //   {
  //     "winery": "Stout Family Wines",
  //     "winery_id": 7
  //   },
  //   {
  //     "winery": "Dyer Vineyard",
  //     "winery_id": 8
  //   },
  //   {
  //     "winery": "Pomum Cellars",
  //     "winery_id": 9
  //   }
  // ];
  filteredWinesByWinery: Array<Wine> = [];
  selectedWinery: string;

  choice: string;
  choices: string;
  subtitle:string;
  asyncResult: any;
  selectedState: string;
  selectedStyle: string;

  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgBgMain = 'bg-main.png' +'?t='+ new Date().getTime();

  states:Array<string>;
  wineColor: string;
  flavorSubSidebar:Array<string>;
  isSparkling: string;

  constructor(
    private appService: AppService,
    private router: Router,
    private api: AppService
    ) {
      this.states = this.appService.states;
  }

  ngOnInit(): void {
    sessionStorage.removeItem('selectedStyle');
    if (!sessionStorage.getItem('selectedState')) {
      this.selectedState = 'all';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState')
    }
    this.urlLogos = this.api.urlImages;

    if(!sessionStorage.getItem('selectedStyle') && this.selectedState != 'all') {
      this.appService.getWinesByState(this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else {      
      this.getAllWines();
    }
    this.filteredWinesByWinery = this.wines;    
    this.getAllStyles();
    this.changeWords();

    this.getWineriesList()
    console.log(this.wineriesList)
  }

  async changeWords(){      
    this.loading = true;
    this.asyncResult = await this.appService.getType().toPromise();

    let shopType = this.asyncResult.site_type;

    switch (shopType) {
      case "cider":
        this.choice = "Cider";
        this.choices = "Ciders";
        this.subtitle = `${this.choices} by Winery`;
      break;

      case "wine":
        this.choice = "Wine";
        this.choices = "Wines";
        this.subtitle = `${this.choices} by Winery`;
      break;
    
      default:
        break;
    }

}

getWineriesList(): void {
  this.appService.getWineriesList().subscribe(
    res => {
      console.log(res)
      this.wineries = []
      let wines = res
      Object.keys(wines).forEach(key => {
        this.wineries.push({
          title: key,
          param: wines[key]
        });
      });
      //console.log(this.wineriesList);
    }
  );
}

  getAllStyles(): void {
    this.appService.getAllStyles().subscribe(
      res => {
        this.types = [];
        res.varietals.forEach(item => {
          this.types.push({ title: item, param: item });
        });
      });
  }

  getAllWines(): void {
    this.noResults = false;
    this.loading = true;
    this.appService.getAllWines().subscribe(res => {
      this.wines = res;
      this.filteredWinesByWinery = res;      
      if (res.length == 0) {
        this.noResults = true;
      }
      this.loading = false;
    });
  }

  onSelectedSidebarOption(path: string): void {
    sessionStorage.removeItem('selectedStyle');
    sessionStorage.removeItem('selectedState');
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

  onSelectedSubSidebarOption(param: string): void {
    this.noResults = false;
    if (!sessionStorage.getItem('selectedState')) {
      this.selectedState = 'all';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState')
    }
    this.loading = true;
    sessionStorage.setItem('selectedStyle', param);
    this.selectedStyle = sessionStorage.getItem('selectedStyle');

    if(this.selectedState == 'all') {
      // this.appService.getWinesByDynamicStyle(this.selectedStyle).subscribe(res => {
      this.appService.getAllWines().subscribe(res => {
        this.wines = res;
        this.loading = false;
      });
    } else {
      this.appService.getWinesByDynamicStyle(this.selectedStyle, this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    }
    this.selectedWinery = param;
    this.appService.getWinesByWinery(this.selectedWinery).subscribe(res => {
      this.filteredWinesByWinery = this.appService.shuffleArray(res); 
      if (res.length == 0) {
        this.appService.getSparklingWinesByWinery(this.selectedWinery).subscribe(resSparkling => {
          this.filteredWinesByWinery = this.appService.shuffleArray(resSparkling)
          if (resSparkling.length == 0) {
            this.noResults = true;
          }
        })
        
      }
      this.loading = false;
    });
    //this.filteredWinesByWinery = this.wines.filter((wine : any) => wine.winery === this.selectedWinery);
  }

  pickStateHandler(state: any): void {
    this.noResults = false;
    this.loading = true;
    sessionStorage.setItem('selectedState', state);
    this.selectedState = sessionStorage.getItem('selectedState');

    if(!sessionStorage.getItem('selectedStyle') && this.selectedState != 'all') {
      this.appService.getWinesByState(this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else if(!sessionStorage.getItem('selectedStyle') && this.selectedState == 'all') {
      this.getAllWines();
    } else {
      this.appService.getWinesByDynamicStyle(this.selectedStyle, this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    }
  }

  onMenuOpen(isMenuOpen: boolean): void {
    this.isMenuOpen = isMenuOpen;
  }
}
