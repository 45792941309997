<nav class="sidebar sidebar--sub" *ngIf="activePage === 'Browse'">
    <a class="sidebar--sub__burger" (click)="open()">
        <svg *ngIf="!isMenuOpen; else closeMenu" class="icon" className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg> Menu
    </a>

    <ng-template #closeMenu>
        <svg class="icon" className="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
    </ng-template>

    <div class="row my-4">
        <img class="sidebar--sub__logo" [src]='urlLogos + imgNameMain' alt="" />
    </div>

    <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="height: 10vh;">
        <span class="mr-10">Loading</span>
        <i class="d-block fas fa-sync fa-spin"></i>
    </div>

    <h1 *ngIf="!loading" class="sidebar--sub__title">Styles of {{choices}}</h1>

    <ul *ngFor="let type of types; let i = index">
        <li class="sidebar--sub__item" (click)="filter(type)" [class.sidebar--sub__item--selected]="selectedFilter.title === type.title">
            {{ type.title }}
        </li>
    </ul>
</nav>

<nav class="d-flex flex-column justify-content-between sidebar sidebar--sub" *ngIf="activePage === 'Explore'">
    <div class="mb-40">
        <a class="sidebar--sub__burger" (click)="open()">
            <svg *ngIf="!isMenuOpen; else closeMenu" class="icon" className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg> Menu
        </a>

        <ng-template #closeMenu>
            <svg class="icon" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
        </ng-template>

        <div class="row my-4">
            <img class="sidebar--sub__logo" [src]='urlLogos + imgNameMain' alt="" />
        </div>

        <ng-container *ngIf="!loading && shopType != 'cider'">
            <h1 class="sidebar--sub__title">What color {{shopType}} are you in the mood for?</h1>
            <div class="d-flex align-items-center justify-content-between">
                <span (click)="checkColor(i)" *ngFor="let color of colors; let i = index" class="filter__btn" [class.filter__btn--selected]="color.isActive">{{ color.title }}</span
        >
            </div>

            <!-- Sparkling Wine Toogle -->
            <div class="sidebar--sub__toggle">
                <span class="mr-10">Sparkling wine? </span>
                <img (click)="toggleSparkling()" src="assets/toggle-{{ sparkling ? 'on' : 'off' }}.svg" />
            </div>
        </ng-container>

        <div class="sidebar--sub__subtitle mt-30">Flavor</div>

        <div class="filter__text">Select</div>

        <ng-container *ngFor="let flavor of flavors; let i = index">
            <div class="filter" [class.filter--active]="flavor.isActive" *ngIf="isVisibleFlavor(flavor)">
                <div class="filter__container">
                    <div class="d-flex justify-content-between align-items-center mb-10">
                        <div class="filter__label">{{ flavor.title }}</div>
                        <div class="filter__icon" *ngIf="flavor.info.length > 0" [ngClass]="{ 'filter__icon--active': i === infoIndex }" (click)="showInfo(i)">
                            <svg height="15" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m9 5h2v2h-2zm0 4h2v6h-2zm1-9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                  fill="#fff"
                />
              </svg>
                        </div>
                    </div>

                    <app-tap-slider class="filter__slider" [resetSliders]="resetSliders" (valueChange)="onTapChange($event, flavor)"></app-tap-slider>
<!--                     <app-tick-slider></app-tick-slider>
 -->
                    <div class="filter__value">
                        <span>{{ flavor.floorTitle }}</span>
                        <span>{{ flavor.ceilTitle }}</span>
                    </div>
                </div>

                <div class="filter__checkbox">
                    <div class="filter__check" (click)="checkFlavor(i)">
                        <span class="filter__check--selected" *ngIf="flavor.isActive"></span>
                    </div>
                </div>

                <div class="filter__info" *ngIf="i === infoIndex">
                    <span>{{ flavor.info }}</span>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="sidebar--sub__wrapper">
        <button class="sidebar--sub__btn" (click)="reset()">Reset</button>
    </div>
</nav>