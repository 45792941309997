import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { TokenInterceptor } from './shared/token.interceptor';
import { AppService } from './app.service';

import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { CustomizationPanelComponent } from './panel/customization-panel/customization-panel.component';
import { BrowseComponent } from './browse/browse.component';
import {FullMenuComponent } from './fullmenu/fullmenu.component';
import { DiscoverComponent } from './discover/discover.component';
import { ExploreComponent } from './explore/explore.component';

import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
import { WineriesComponent } from './wineries/wineries.component';
import { SubSidebarWineriesComponent } from './sub-sidebar-wineries/sub-sidebar-wineries.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BrowseComponent,
    ExploreComponent,
    DiscoverComponent,
    FullMenuComponent,
    CustomizationPanelComponent,
    WineriesComponent,
    SubSidebarWineriesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    MatSliderModule,
    ColorPickerModule
  ],
  providers: [
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
