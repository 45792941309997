<app-sidebar class="m-hidden" activePage="Explore" (selected)="onSelectedSidebarOption($event)"></app-sidebar>

<div class="page content" [ngClass]="{ 'page--menuOpen': isMenuOpen }">
    <app-mobile-header title="Explore" [subtitle]=subtitle></app-mobile-header>

    <div class="d-flex flex-column flex-sm-row">
        <div class="sub-sidebar-wrapper m-hidden" *ngIf="!isMobile">
            <app-sub-sidebar activePage="Explore" (selected)="onSelectedSubSidebarOption($event)" (menuOpen)="onMenuOpen($event)"></app-sub-sidebar>
        </div>

        <app-mobile-filter class="t-hidden" *ngIf="isMobile" activePage="Explore" (selectedFlavor)="onSelectedMobileOption($event)" (activeStepChanged)="onChangeActiveStep($event)" (menuOpen)="onMenuOpen($event)"></app-mobile-filter>

        <!-- Mobile-->
        <section class="content-inner t-hidden" *ngIf="isMobile">

            <div *ngIf="activeStep && activeStep === 2">
                <!-- <div class="col-12 col-md-3">
                    <p class="header__shipping">Select a State for shipping</p>
                    <select class="header__select" [(ngModel)]="selectedState" (change)="pickStateHandler(selectedState)">
                        <option *ngIf="selectedState == ''" value="" selected disabled>Select State</option>
                        <option *ngIf="selectedState != ''" value="all">See All</option>
                        <option *ngFor="let st of states" [value]="st">{{st}}</option>
                    </select>
                </div> -->

                <h1 class="content-inner__label">Top {{choices}} for you</h1>
                <!-- <p class="state-of-shipping-text" *ngIf="selectedState">State for shipping: {{selectedState}}</p> -->
                <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="height: 100vh;">
                    <span class="mr-10">Loading</span>
                    <i class="d-block fas fa-sync fa-2x fa-spin"></i>
                </div>

                <section *ngIf="!loading" class="row">
                    <app-item [item]="wine" [counter]="i+1" *ngFor="let wine of wines; let i = index" class="col-md-6 col-lg-4"></app-item>
                    <p class="noResults" *ngIf="noResults">{{noResultsText}}</p>
                </section>
            </div>
        </section>

        <!-- Tablet-->
        <section class="content-inner m-hidden" *ngIf="!isMobile">
            <!-- <app-header title="Recommend" [subtitle]=subtitle [states]=states (selectedShippingState)="pickStateHandler($event)"></app-header>

            <div>
                <h1 class="content-inner__label">Top {{choices}} for you</h1>
                <p class="state-of-shipping-text" *ngIf="selectedState">State for shipping: {{selectedState}}</p>
                <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="height: 100vh;">
                    <span class="mr-10">Loading</span>
                    <i class="d-block fas fa-sync fa-2x fa-spin"></i>
                </div>


                <section *ngIf="!loading" class="row">
                    <app-item [item]="wine" [counter]="i+1" *ngFor="let wine of wines; let i = index" class="col-md-6 col-lg-4 mt-15 mb-15"></app-item>
                    <p class="noResults" *ngIf="noResults">{{noResultsText}}</p>
                </section>
            </div> -->

            <app-header title="Explore" subtitle="Wines by Flavor"></app-header>

            <div>
              <h1 class="content-inner__label">Top 3 wines for you</h1>
      
              <section class="row">
                <app-item
                  [item]="wine"
                  *ngFor="let wine of wines"
                  class="col-md-6 col-lg-4"
                ></app-item>
              </section>
            </div>
        </section>
    </div>
</div>