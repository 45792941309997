import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit{
  @Input() title: string;
  @Input() subtitle: string;

  links = this.appService.sideBarLinks;

  isVisible: boolean;
  isSurveyModalOpen: boolean;

  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgNameIso: string = 'iso-logo-vine.png' +'?t='+ new Date().getTime();

  constructor(private appService: AppService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.urlLogos = this.appService.urlImages;
  }

  select(page: string): void {
    this.router.navigate([page]).then(() => {
      this.close();
    });
  }

  goTo(route: string): void {
    sessionStorage.removeItem('selectedStyle');
    sessionStorage.removeItem('selectedState');
    this.router.navigate([route]);
  }

  open(): void {
    this.isVisible = true;
  }

  close(): void {
    this.isVisible = false;
  }

  onOpenSurveyModal(): void {
    this.isSurveyModalOpen = true;
  }

  closeSurveyModal(): void {
    this.isSurveyModalOpen = false;
  }
}
