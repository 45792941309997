import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Wine } from '../../../models/wine.model';

@Component({
  selector: 'app-wine-modal',
  templateUrl: './wine-modal.component.html',
  styleUrls: ['./wine-modal.component.scss'],
})
export class WineModalComponent implements OnInit {
  @Input() wine: Wine;

  @Output() readonly responseChanged = new EventEmitter();

  flavors = [];
  sparklingFlavors = [];

  ngOnInit(): void {
    this.flavors = [
      {
        title: 'Sweetness',
        percent: this.getPercent(this.wine.characteristics.sweetness),
      },
      {
        title: 'Intensity',
        percent: this.getPercent(this.wine.characteristics.body),
      },
      {
        title: 'Fruit',
        percent: this.getPercent(this.wine.characteristics.fruit),
      },
      {
        title: 'Oak',
        percent: this.getPercent(this.wine.characteristics.oak),
      },
      /*{
        title: 'Finish',
        percent: this.getPercent(this.wine.characteristics.finish),
      },*/
      {
        title: 'Acidity',
        percent: this.getPercent(this.wine.characteristics.acidity),
      },
      {
        title: 'Spice',
        percent: this.getPercent(this.wine.characteristics.spice),
      },
      {
        title: 'Floral',
        percent: this.getPercent(this.wine.characteristics.floral),
      },
      {
        title: 'Minerality',
        percent: this.getPercent(this.wine.characteristics.minerality),
      },
    ];
    this.sparklingFlavors = [
      {
        title: 'Acidity',
        percent: this.getPercent(this.wine.characteristics.acidity),
      },
      {
        title: 'Yeast / brioche',
        percent: this.getPercent(this.wine.characteristics.yeasty),
      },
      {
        title: 'Citrus Fruit',
        percent: this.getPercent(this.wine.characteristics.citrus_fruit),
      }, 
      {
        title: 'Red Fruit',
        percent: this.getPercent(this.wine.characteristics.red_fruit),
      }, 
      {
        title: 'Creamy',
        percent: this.getPercent(this.wine.characteristics.creamy),
      },
    ];

    this.flavors = this.flavors.filter(flavor => flavor.percent >= 0);
    this.sparklingFlavors = this.sparklingFlavors.filter(flavor => flavor.percent >= 0);


  }

  getPercent(value: number): number {
    
    if (value !== undefined)
    {
      return (value / 10) * 100;
    }

    return -1;
    //return (value / 6) * 100;
  }

  close(): void {
    this.responseChanged.emit();
  }
}
