import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  selectedState: string;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() states: Array<string>;

  @Output() readonly selectedShippingState = new EventEmitter();

  isSurveyModalOpen: boolean;

  ngOnInit(): void {
    if(!sessionStorage.getItem('selectedState')) {
      this.selectedState = '';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState');
    }
    
  }

  onOpenSurveyModal(): void {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdShAHRAF6wCWlNemspdmev59qL3sbdpj8t4u07WSmdkWJXFw/viewform?vc=0&c=0&w=1&flr=0', "_blank");

//    this.isSurveyModalOpen = true;
  }

  pickState(state: string): void {
    //console.log(state);
    this.selectedShippingState.emit(state);
  }

  close(): void {
    this.isSurveyModalOpen = false;
  }
}
