import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-fullmenu',
  templateUrl: './fullmenu.component.html',
  styleUrls: ['./fullmenu.component.scss'],
  styles: [
    `:root {
      --first-color: primaryColor;
      --second-color: secondaryColor;
      --third-color: tertiaryColor;
    }`
  ]
})
export class FullMenuComponent implements OnInit{

  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;

  colors:any = [];

  loading:boolean = false;

  constructor(
    private router: Router,
    private appService: AppService
    ) {
  }

  ngOnInit(): void {

    this.getTheme();
    
  }


  getTheme() {
    this.loading = true;
    this.appService.getTheme()
      .subscribe(data => {
        this.colors = data;
      
        this.primaryColor = this.colors[0].value;
        this.secondaryColor = this.colors[1].value;
        this.tertiaryColor = this.colors[2].value;

        document.documentElement.style.setProperty('--first-color', this.primaryColor);
        document.documentElement.style.setProperty('--second-color', this.secondaryColor);
        document.documentElement.style.setProperty('--third-color', this.tertiaryColor);
        this.loading = false;
      })
  }

  onSelectedSidebarOption(path: string): void {
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }
}
