<!-- <div class="header t-hidden t-hidden--fx">
    <div class="d-flex align-items-center row">
        <div class="col-7" (click)="goTo('/home')">
            <img class="header__logo" [src]='urlLogos + imgNameMain' alt="" />
        </div>
        <div class="col-5">
            <h1 class="header__title">{{ title }}</h1>
            <h2 class="header__subtitle" [innerHTML]="subtitle | titlecase"></h2>
        </div>
    </div>

    <div class="header__icon" (click)="open()">
        <svg height="22" viewBox="0 0 4 22" width="4" xmlns="http://www.w3.org/2000/svg">
      <g fill="#fff" fill-rule="evenodd">
        <circle cx="2" cy="2" r="2"/>
        <circle cx="2" cy="11" r="2"/>
        <circle cx="2" cy="20" r="2"/>
      </g>
    </svg>
    </div>
</div>

<div class="header__nav" *ngIf="isVisible">
    <div class="d-flex justify-content-end">
        <svg (click)="close()" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#4D4D4D" style="stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round">
        <path d="m2 2 22 22"/>
        <path d="m24 2-22 22"/>
      </g>
    </svg>
    </div>

    <div class="d-flex justify-content-center header__inner-logo" (click)="goTo('/home')">
        <img [src]='urlLogos + imgNameMain' />
    </div>

    <ul>
        <li *ngFor="let link of links" class="header__item" (click)="select(link.link)" [innerHTML]="link.title"></li>

        <li class="header__item" (click)="onOpenSurveyModal()">Tell us what you think!</li>
    </ul>
</div>

<app-rodeo-survey *ngIf="isSurveyModalOpen" (responseChanged)="closeSurveyModal()"></app-rodeo-survey>
 -->
 <div class="header t-hidden t-hidden--fx">
  <div class="d-flex align-items-center">
    <div class="mr-20" (click)="goTo('/home')">
      <img class="header__logo" src="assets/logo-mobile.png" alt=""/></div>

    <div>
      <h1 class="header__title">{{ title }}</h1>
      <h2 class="header__subtitle" [innerHTML]="subtitle"></h2>
    </div>
  </div>

  <div class="header__icon" (click)="open()">
    <svg height="22" viewBox="0 0 4 22" width="4" xmlns="http://www.w3.org/2000/svg">
      <g fill="#fff" fill-rule="evenodd">
        <circle cx="2" cy="2" r="2"/>
        <circle cx="2" cy="11" r="2"/>
        <circle cx="2" cy="20" r="2"/>
      </g>
    </svg>
  </div>
</div>

<div class="header__nav" *ngIf="isVisible">
  <div class="d-flex justify-content-end">
    <svg (click)="close()" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#4D4D4D" style="stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round">
        <path d="m2 2 22 22"/>
        <path d="m24 2-22 22"/>
      </g>
    </svg>
  </div>

  <div class="d-flex justify-content-center header__inner-logo" (click)="goTo('/home')">
    <img src="assets/logo-inner.png"/>
  </div>

  <ul>
    <li *ngFor="let link of links" class="header__item" (click)="select(link.link)"
        [innerHTML]="link.title"></li>

    <li class="header__item" (click)="onOpenSurveyModal()">Tell us what you think!</li>
  </ul>
</div>

<app-rodeo-survey *ngIf="isSurveyModalOpen"
                  (responseChanged)="closeSurveyModal()"></app-rodeo-survey>
