import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-customization-panel',
  templateUrl: './customization-panel.component.html',
  styleUrls: ['./customization-panel.component.scss'],
  styles: [
    `:root {
      --first-color: primaryColor;
      --second-color: secondaryColor;
      --third-color: tertiaryColor;
    }`
  ]
})
export class CustomizationPanelComponent implements OnInit {

  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  saveChanges: string = "Save changes";

  mainLogo: string;
  isoLogo: string;
  mainBg: string;
  base64Img: any = [];
  imagenActual: string = "";
  imagenActualIso: string = "";
  imagenActualBg: string = "";

  color: string;

  colors:any = [];
  imgs:any = [];
  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgNameIso: string = 'iso-logo-vine.png' +'?t='+ new Date().getTime();
  imgBgMain: string = 'bg-main.png' +'?t='+ new Date().getTime();

  screenColors: boolean = true;
  screenImages: boolean = false;

  loading:boolean = false;
  btnMainDisabled:boolean;
  btnIsoDisabled:boolean;
  btnBgDisabled:boolean;
  btnBgDefaultDisabled:boolean;

  invalidFormatText:string = '';
  invalidFormatTextIso:string = '';
  invalidFormatTextBg: string = '';

  constructor(
    private router: Router,
    private api: AppService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    this.urlLogos = this.api.urlImages;
    this.btnMainDisabled = true;
    this.btnIsoDisabled = true;
    this.btnBgDisabled = true;
    this.getTheme();
    
  }

  changeTheme(primary: string, secondary: string, tertiary: string) {
    this.loading = true;
    this.colors[0].value = primary;
    this.colors[1].value = secondary;
    this.colors[2].value = tertiary;


    this.api.updateColors(this.colors).subscribe(
      data => {
        this.loading = false;
        if (data=='updated') {
          Swal.fire({
            title: 'Colors Updated!',
            text: 'Continue to site',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          .then((result)=> {
            if(result){
              this.saveChanges = "Changes saved";
              this.getTheme();
    
              Swal.mixin({
                showClass: {
                  popup: 'stepsAlert stepsAlertProgress'
                },
                confirmButtonText: 'Next &rarr;',
                showCloseButton: true,
                progressSteps: ['1', '2', '3', '4']
              }).queue([
                {
                  title: 'Home page',
                  imageUrl: './assets/steps/step1.jpg',
                  imageAlt: 'step1.jpg',
                  confirmButtonText: 'Next &rarr;',
                  showCloseButton: true,
                },
                {
                  title: 'Main pages',
                  imageUrl: './assets/steps/step2.jpg',
                  imageAlt: 'step2.jpg',
                  confirmButtonText: 'Next &rarr;',
                  showCloseButton: true,
                },
                {
                  title: 'Lateral Menu',
                  imageUrl: './assets/steps/step3.jpg',
                  imageAlt: 'step3.jpg',
                  confirmButtonText: 'Next &rarr;',
                  showCloseButton: true,
                },
                {
                  title: 'Choice detail',
                  imageUrl: './assets/steps/popup.jpg',
                  imageAlt: 'popup.jpg',
                  confirmButtonText: '<i class="fas fa-times"></i> Close',
                  showCloseButton: true,
                },
              ])/*.then((result) => {
                if (result) {
                  
                  Swal.fire({
                    title: 'Thanks!',
                    confirmButtonText: 'Continue'
                  })
                }
              })*/

            }
          })
          //this.router.navigate([this.router.url])
          setTimeout(() => {
            this.saveChanges = "Save changes";
          }, 4000);
        } else {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Try again later',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
    });
  }

  getTheme() {
    this.loading = true;
    this.api.getTheme()
      .subscribe(data => {
        this.colors = data;
      
        this.primaryColor = this.colors[0].value;
        this.secondaryColor = this.colors[1].value;
        this.tertiaryColor = this.colors[2].value;

        document.documentElement.style.setProperty('--first-color', this.primaryColor);
        document.documentElement.style.setProperty('--second-color', this.secondaryColor);
        document.documentElement.style.setProperty('--third-color', this.tertiaryColor);

        this.loading = false;
      })
  }

  onUploadChangeImg(evt: any) {

    this.invalidFormatText = '';
    const ext = evt.target.files[0].name.split('.').pop();

    if(ext == "jpeg" || ext == "jpg"  || ext == "png") {
      
      this.base64Img = [];
      const file = evt.target.files[0];
      this.btnMainDisabled = false;
      if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderImg.bind(this);
        reader.readAsBinaryString(file);
      }
    
    } else {
      this.invalidFormatText = "File format not valid, please use .jpg or .png files only";
      this.btnMainDisabled = true;
      return;
    }
 
  }

  onUploadChangeIsoImg(evt: any) {

    this.invalidFormatTextIso = '';
    const ext = evt.target.files[0].name.split('.').pop();

    if(ext == "jpeg" || ext == "jpg"  || ext == "png") {

      this.base64Img = [];
      const file = evt.target.files[0];
      this.btnIsoDisabled = false;
      if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderImgIso.bind(this);
        reader.readAsBinaryString(file);
      }

    } else {
      this.invalidFormatTextIso = "File format not valid, please use .jpg or .png files only";
      this.btnIsoDisabled = true;
      return;
    }
  }

  onUploadChangeBg(evt: any) {

    this.invalidFormatTextBg = '';

    if(!evt.target.files[0]) {

      //this.btnBgDefaultDisabled = false;
      this.btnBgDisabled = true;
      this.imagenActualBg = '';
      return;
    }

    const ext = evt.target.files[0].name.split('.').pop();

    if(ext == "jpeg" || ext == "jpg"  || ext == "png") {

      this.base64Img = [];
      const file = evt.target.files[0];
      this.btnBgDisabled = false;
      if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderImgBg.bind(this);
        reader.readAsBinaryString(file);
      }

    } else {
      this.invalidFormatTextBg = "File format not valid, please use .jpg or .png files only";
      this.btnBgDisabled = true;
      return;
    }
  }

  handleReaderImg(e) {
    this.mainLogo = 'data:image/png;base64,' + btoa(e.target.result);
    this.imagenActual = 'data:image/png;base64,' + btoa(e.target.result);
  }

  handleReaderImgIso(e) {
    this.isoLogo = 'data:image/png;base64,' + btoa(e.target.result);
    this.imagenActualIso = 'data:image/png;base64,' + btoa(e.target.result);
  }

  handleReaderImgBg(e) {
    this.mainBg = 'data:image/png;base64,' + btoa(e.target.result);
    this.imagenActualBg = 'data:image/png;base64,' + btoa(e.target.result);
    this.btnBgDefaultDisabled = true;
  }


  updateImg() {
    this.invalidFormatText = '';
    this.invalidFormatTextIso = '';
    this.invalidFormatTextBg = '';

    this.loading = true;

    this.api.updateMainLogo(this.mainLogo).subscribe(
      data => {
        if (data=='updated') {

          Swal.fire({
            title: 'Main Image Updated!',
            text: 'Continue to site',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          .then((result)=> {
            if(result){
              this.imagenActual = '';
              this.imgNameMain = this.imgNameMain +'?t='+ new Date().getTime();
              this.loading = false;
              this.btnMainDisabled = true;
  
              Swal.mixin({
                showClass: {
                  popup: 'stepsAlert stepsAlertProgress'
                },
                confirmButtonText: 'Next &rarr;',
                showCloseButton: true,
                progressSteps: ['1', '2']
              }).queue([
                {
                  title: 'Home page',
                  imageUrl: './assets/steps/mainLogoHome.jpg',
                  imageAlt: 'mainLogoHome.jpg',
                  confirmButtonText: 'Next &rarr;',
                  showCloseButton: true,
                },
                {
                  title: 'Logo in menu',
                  imageUrl: './assets/steps/mainLogoMenu.jpg',
                  imageAlt: 'mainLogoMenu.jpg',
                  confirmButtonText: '<i class="fas fa-times"></i> Close',
                  showCloseButton: true,
                },
              ])
            }
          })
        } else {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Try again later',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
    });
  }

  updateIsologo() {
    this.invalidFormatText = '';
    this.invalidFormatTextIso = '';
    this.invalidFormatTextBg = '';

    this.loading = true;
    
    this.api.updateIsoLogo(this.isoLogo).subscribe(
      data => {
        //console.log("vuelta", data);
        if (data=='updated') {

          Swal.fire({
            title: 'Isologo Updated!',
            text: 'Continue to site',
            icon: 'success',
            confirmButtonText: 'Ok'
          })

          .then((result)=> {
            if(result){
              this.imagenActualIso = '';
              this.imgNameIso = this.imgNameIso +'?t='+ new Date().getTime();
              this.loading = false;
              this.btnIsoDisabled = true;
  
              Swal.fire({
                imageUrl: './assets/steps/isologo.jpg',
                imageAlt: 'isologo.jpg',
                confirmButtonText: '<i class="fas fa-times"></i> Close',
                showCancelButton: false,
              })
            }
          })
        } else {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Try again later',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
    });
  }

  updateMainBg() {
    this.invalidFormatText = '';
    this.invalidFormatTextIso = '';
    this.invalidFormatTextBg = '';

    this.loading = true;
    
    this.api.updateMainBg(this.mainBg).subscribe(
      data => {
        //console.log("vuelta", data);
        if (data=='updated') {

          Swal.fire({
            title: 'Main Backgorund updated!',
            text: 'Continue to site',
            icon: 'success',
            confirmButtonText: 'Ok'
          })

          .then((result)=> {
            if(result){
              this.imagenActualBg = '';
              this.imgBgMain = 'bg-main.png' +'?t='+ new Date().getTime();
              this.loading = false;
              this.btnBgDisabled = true;
  
              /*Swal.fire({
                imageUrl: './assets/steps/isologo.jpg',
                imageAlt: 'isologo.jpg',
                confirmButtonText: '<i class="fas fa-times"></i> Close',
                showCancelButton: false,
              })*/
            }
          })
        } 
        else {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Try again later',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          .then((result)=> {
            if(result){
              this.loading = false;
            }
          })
        }
    });
  }

  updateDefaultBg() {
    this.invalidFormatText = '';
    this.invalidFormatTextIso = '';
    this.invalidFormatTextBg = '';

    //console.log("this.urllogos", this.urlLogos);

    let bgDef = this.urlLogos + 'bg-transparent.png';
    this.loading = true;
    
    //console.log("bgDef: ", bgDef);

    this.api.updateDefBg(bgDef).subscribe(
      data => {
        //console.log("vuelta", data);
        if (data=='updated') {

          Swal.fire({
            title: 'Main Backgorund updated!',
            text: 'Continue to site',
            icon: 'success',
            confirmButtonText: 'Ok'
          })

          .then((result)=> {
            if(result){
              this.imagenActualBg = '';
              this.imgBgMain = 'bg-main.png' +'?t='+ new Date().getTime();
              this.loading = false;
              this.btnBgDisabled = true;              
            }
          })
        } 
        else {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Try again later',
            icon: 'error',
            confirmButtonText: 'Ok'
          })

          .then((result)=> {
            if(result){
              this.loading = false;
            }
          })
        }
    });
    
  }

  showScreen(screen) {

    this.invalidFormatText = '';
    this.invalidFormatTextIso = '';
    this.invalidFormatTextBg = '';

    if (screen === 'colors') {
      this.screenColors = true;
      this.screenImages = false;
    } else if (screen === 'images') {
      this.screenColors = false;
      this.screenImages = true;
    }
  }

  showPreview(section) {

    let bg:string;

    switch (section) {
      case 'home':
        bg = 'step1.jpg'
      break;

      case 'main':
        bg = 'step2.jpg'
      break;

      case 'lateral':
        bg = 'step3.jpg'
      break;

      case 'mainLogoHome':
        bg = 'mainLogoHome.jpg'
      break;

      case 'mainLogoMenu':
        bg = 'mainLogoMenu.jpg'
      break;

      case 'isoLogo':
        bg = 'isologo.jpg'
      break;

      case 'detail':
        bg = 'popup.jpg'
      break;
    
      default:
        break;
    }
    
    Swal.fire({
      imageUrl: './assets/steps/' + bg,
      confirmButtonText: '<i class="fas fa-times"></i> Close',
      imageAlt: 'bg',
      showClass: {
        popup: 'stepsAlert'
      },
    })
  }

  goTo(path: string): void {
    this.invalidFormatText = '';
    this.invalidFormatTextIso = '';
    this.invalidFormatTextBg = '';
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

}
