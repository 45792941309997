<app-sidebar class="m-hidden" activePage="Browse" (selected)="onSelectedSidebarOption($event)"></app-sidebar>

<div class="page content" [ngClass]="{ 'page--menuOpen': isMenuOpen }">
    <app-mobile-header title="View" [subtitle]=subtitle></app-mobile-header>

    <div class="d-flex flex-column flex-sm-row">
        <div class="sub-sidebar-wrapper m-hidden">
            <app-sub-sidebar *ngIf="types.length > 0" activePage="Browse" [types]="types" (selectedStyles)="onSelectedSubSidebarOption($event)" (menuOpen)="onMenuOpen($event)"></app-sub-sidebar>
        </div>

        <app-mobile-filter class="t-hidden" *ngIf="types.length > 0" activePage="Browse" [types]="types" (selectedParam)="onSelectedSubSidebarOption($event)" (menuOpen)="onMenuOpen($event)"></app-mobile-filter>
        <!-- <div class="col-12 col-md-3 t-hidden mt-25">
            <p class="header__shipping">Select a State for shipping</p>
            
            <select class="header__select" [(ngModel)]="selectedState" (change)="pickStateHandler(selectedState)">
                <option *ngIf="selectedState == ''" value="" selected disabled>Select State</option>
                <option *ngIf="selectedState != ''" value="all">See All</option>
                <option *ngFor="let st of states" [value]="st">{{st}}</option>
            </select>
            <p class="state-of-shipping-text mt-25" *ngIf="selectedState">State for shipping: {{selectedState}}</p>
        </div> -->

        <section class="content-inner">
            <app-header class="m-hidden" title="View" [subtitle]=subtitle [states]=states (selectedShippingState)="pickStateHandler($event)"></app-header>
            <!-- <p class="state-of-shipping-text m-hidden" *ngIf="selectedState">State for shipping: {{selectedState}}</p> -->
            <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="height: 100vh;">
                <span class="mr-10">Loading</span>
                <i class="d-block fas fa-sync fa-2x fa-spin"></i>
            </div>

            <section *ngIf="!loading" class="row">
                <app-item *ngFor="let wine of wines" [counter]=null [item]="wine" class="col-md-12 col-lg-4 mt-15 mb-15"></app-item>
                <p class="noResults" *ngIf="noResults">{{noResultsText}}</p>
            </section>
        </section>
    </div>
</div>