<!-- Tablet -->
<article class="item m-hidden desktop-item" >
    <div class="d-inline-flex flex-column pointer" (click)="select()">
        <span class="item__number">{{counter}}</span>
        <img [src]="item.image_url" style="height: 474px; object-fit: contain;" />
        <!-- <div class="item__property__img mr-20" [style.backgroundImage]="'url(' + item.image_url + ')'"></div> -->

        <h1 class="item__name">{{ item?.name }}</h1>
        <div class="item__property">
            <ng-container *ngIf="item?.category">
                <p class="mb-10"><span class="bold">Category: </span> {{ item?.category }}</p>
            </ng-container>
            <ng-container *ngIf="item?.vintage">
                <p class="mb-10"><span class="bold">Vintage: </span> {{ item?.vintage }}</p>
            </ng-container>
            <ng-container *ngIf="item?.awards">
                <p class="mb-10"><span class="bold">Awards: </span> {{ item?.awards }}</p>
                <p *ngIf="item?.description"> {{ item?.description | slice:0:100 }}...</p>

            </ng-container>
            <!-- <ng-container *ngIf="item?.description">
                <p><span class="bold">Description: </span> {{ item?.description | slice:0:100 }}...</p>
            </ng-container>
     -->
            <div class="item__property mt-10 mb-10" *ngIf="item?.price">
                <div class="mb-5">
                    <p><span class="bold">Price: </span> {{ item?.price }}</p>
                </div>
            </div>
            <div class="buy-button">
                <button type="button" class="btn btn--buy">See more</button>
            </div> 
            
        </div>
    </div>
</article>

<!-- Mobile --> 
<article class="item item-m d-flex pointer t-hidden t-hidden--fx" (click)="select()">
    <span class="item__number">{{counter}}</span>
    <div class="item__property__img mr-20" [style.backgroundImage]="'url(' + item.image_url + ')'"></div>
    <div>
        <h1 class="item__name">{{ item?.name }}</h1>
        <div class="item__property">
            <ng-container *ngIf="item?.category">
                <p class="mb-10"><span class="bold">Category: </span> {{ item?.category }}</p>
            </ng-container>
            <ng-container *ngIf="item?.vintage">
                <p class="mb-10"><span class="bold">Vintage: </span> {{ item?.vintage }}</p>
            </ng-container>
        
            <ng-container *ngIf="item?.description">
                <p><span class="bold">Description: </span> {{ item?.description | slice:0:50 }}...</p>
            </ng-container>
        </div>
   
        <div class="item__property mt-10" *ngIf="item?.price">
            <div class="mb-5">
                <p><span class="bold">Price: </span> {{ item?.price }} </p>
            </div>
        </div>
        <div class="buy-button">
            <button type="button" class="btn btn--buy">See more</button>
        </div> 
    </div>
</article>

<app-wine-modal [wine]="item" (responseChanged)="close()" *ngIf="isModalVisible"></app-wine-modal> 

