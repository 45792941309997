<div class="page content">
    <app-mobile-header title="Full Menu" subtitle="All Wines in one place"></app-mobile-header>

    <div class="d-flex flex-column flex-sm-row">
        <div class="sidebar-wrapper m-hidden">
            <app-sidebar activePage="Fullmenu" (selected)="onSelectedSidebarOption($event)"></app-sidebar>
        </div>

        <section class="content-inner">
            <app-header class="m-hidden" title="Full Menu" subtitle="All Wines in one place"></app-header>
            <div>
                <img src="assets/menu2024.gif" class="viewport-fill">

            </div>


        </section>
    </div>

</div>