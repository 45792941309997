<div class="header row mx-0">
    <div class="col-12 col-md-9">
        <h1 class="header__title">{{ title }}</h1>
        <h2 class="header__subtitle" [innerHTML]="subtitle | titlecase"></h2>
    </div>
    <div class="col-12 col-md-3">
        <!-- <p class="header__shipping">Select a State for shipping</p>
        <select class="header__select" [(ngModel)]="selectedState" (change)="pickState(selectedState)">
            <option *ngIf="selectedState == ''" value="" selected disabled>Select State</option>
            <option *ngIf="selectedState != ''" value="all">See All</option>
            <option *ngFor="let st of states" [value]="st">{{st}}</option>
        </select> -->
        <button type="button" class="btn btn--outline" (click)="onOpenSurveyModal()">Tell us what you think</button>

    </div>

</div>

<app-rodeo-survey *ngIf="isSurveyModalOpen" (responseChanged)="close()"></app-rodeo-survey>