import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss'],
})
export class MobileFilterComponent implements OnInit {
  @Input() activePage: string;
  @Input() types: Array<any>;

  @Output() readonly selectedFlavor = new EventEmitter();
  @Output() readonly selectedParam = new EventEmitter();
  @Output() readonly menuOpen = new EventEmitter();
  @Output() readonly activeStepChanged = new EventEmitter();

  flavors: Array<any> = this.appService.flavors;
  isMenuOpen: boolean;
  sparkling: boolean;
  expanded: boolean;

  infoIndex: number;
  activeStep: number;
  numberToSlice = 4;

  selectedFilter = {
    title: '',
    param: '',
  };

  colors = [
    { title: 'White', isActive: true },
    { title: 'Red', isActive: false },
    { title: 'Pink', isActive: false },
  ];

  selectedColor = 'White';

  asyncResult: any;
  shopType: string;
  choice: string;
  choices: string;

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
    this.isMenuOpen = false;
    this.emitValues();
    this.replaceString();
  }

  showInfo(index: number): void {
    if (this.flavors[index].isActive) {
      this.infoIndex = this.infoIndex === index ? undefined : index;
    }
  }

  filter(selectedFilter: any): void {
    this.expanded = !this.expanded;
    this.selectedFilter = selectedFilter;
    this.selectedParam.emit(this.selectedFilter.param);
  }

  checkFlavor(index: number): void {
    this.flavors[index].isActive = !this.flavors[index].isActive;
    this.flavors[index].value = 0;
    this.emitValues();
  }

  isVisibleFlavor(flavor: any): boolean {
    return (this.sparkling && flavor.isSparkling) || (!this.sparkling && !flavor.isSparkling);
  }

  checkColor(index: number): void {
    this.colors.map(item => item.isActive = false);

    this.colors[index].isActive = true;
    this.selectedColor = this.colors[index].title;
    this.sparkling = false;
    this.emitValues();
  }

  toggleSparkling(value: boolean): void {
    this.sparkling = value;
    this.emitValues();
  }

  emitValues(): void {
    this.selectedFlavor.emit({ flavors: this.flavors, color: this.selectedColor, sparkling: this.sparkling });
  }

  open(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuOpen.emit(this.isMenuOpen);
  }

  onTapChange(event, flavor): void {
    flavor.value = event;
    this.emitValues();
  }

  onShwMoreLess(): void {
    this.numberToSlice = this.numberToSlice === 4 ? this.flavors.length : 4;
  }

  setActiveStep(step: number): void {
    this.activeStep = step;
    this.activeStepChanged.emit(step);
    this.numberToSlice = this.sparkling ? this.flavors.length : 4;
  }

  async replaceString() {
    //this.loading = true;
    this.asyncResult = await this.appService.getType().toPromise();

    this.shopType = this.asyncResult.site_type;

    this.flavors.forEach(res => {

      let infoChoice = res.info;
      let toRplc = "choice";
      let newstr: string;

      switch (this.shopType) {

        case "cider":
          newstr = infoChoice.replace(/choice/gi, "cider");
          res.info = newstr;
          //this.loading = false;
          this.choice = "Cider";
          this.choices = "Ciders";
          this.activeStep = 2;
          this.setActiveStep(2);
        break;

        case "wine":
          newstr = infoChoice.replace(/choice/gi, "wine");
          res.info = newstr;
          //this.loading = false;
          this.choice = "Wine";
          this.choices = "Wines";
          this.activeStep = 1;
          this.setActiveStep(1);
        break;
      
        default:
          break;
      }

    });

  }
}
