<nav class="mobile-filter" *ngIf="activePage === 'Browse'">
    <div class="mobile-filter__expand" (click)="expanded = false" *ngIf="selectedFilter.title !== '' && expanded">
        Selected style:&nbsp;<span>{{ selectedFilter.title }} </span>
        <i class="fas fa-chevron-down"></i>
    </div>

    <ng-container *ngIf="!expanded">
        <ul class="mobile-filter__items" *ngFor="let type of types; let i = index">
            <li class="mobile-filter__item" (click)="filter(type)" [class.mobile-filter__item--selected]="selectedFilter.title === type.title">
                {{ type.title }}
            </li>
        </ul>
    </ng-container>
</nav>

<nav class="d-flex flex-column justify-content-between mobile-filter mobile-filter--flavor" *ngIf="activePage === 'Explore'">
    <div *ngIf="activeStep === 1">
        <h1 class="mobile-filter__title">What color {{choice}} <br> are you in the mood for?</h1>
        <div class="d-flex align-items-center justify-content-between mb-30">
            <div (click)="checkColor(i)" *ngFor="let color of colors; let i = index" class="filter__btn" [class.filter__btn--selected]="color.isActive">{{ color.title }}</div>
        </div>

        <!-- SPARKLING WINE TOGGLE -->
        <h1 class="mobile-filter__title">Or would you prefer <br> sparkling wine?</h1>

        <div class="mobile-filter__toggle" >
            <div class="d-flex align-items-center" (click)="toggleSparkling(true)">
                <div class="filter__check mt-0 mr-5">
                    <span class="filter__check--selected" *ngIf="sparkling"></span>
                </div>
                <span>Yes, give me bubbles</span>
            </div>
            <div class="d-flex align-items-center mt-10" (click)="toggleSparkling(false)">
                <div class="filter__check mt-0 mr-5">
                    <span class="filter__check--selected" *ngIf="!sparkling"></span>
                </div>
                <span>No bubbles for me</span>
            </div>
        </div>
    </div>
    <div *ngIf="activeStep === 2">
        <div class="mobile-filter__title">Now tell us what<br> flavor you prefer...</div>

        <div class="filter__text">Select</div>

        <ng-container *ngFor="let flavor of flavors | slice: 0:numberToSlice; let i = index">
            <div class="filter" [class.filter--active]="flavor.isActive" *ngIf="isVisibleFlavor(flavor)">
                <div class="filter__container">
                    <div class="d-flex justify-content-between align-items-center mb-15">
                        <div class="filter__label">{{ flavor.title }}</div>
                        <div class="filter__icon" *ngIf="flavor.info.length > 0" [ngClass]="{ 'filter__icon--active': i === infoIndex }" (click)="showInfo(i)">
                            <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m9 5h2v2h-2zm0 4h2v6h-2zm1-9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                  fill="#3a3a3a"
                />
              </svg>
                        </div>
                    </div>
                    <app-tap-slider class="filter__slider" (valueChange)="onTapChange($event, flavor)"></app-tap-slider>

                    <div class="filter__value">
                        <span>{{ flavor.floorTitle }}</span>
                        <span>{{ flavor.ceilTitle }}</span>
                    </div>
                </div>

                <div class="filter__checkbox">
                    <div class="filter__check" (click)="checkFlavor(i)">
                        <span class="filter__check--selected" *ngIf="flavor.isActive"></span>
                    </div>
                </div>

                <div class="filter__info" *ngIf="i === infoIndex">
                    <span>{{ flavor.info }}</span>
                </div>
            </div>
        </ng-container>

    </div>

    <div class="d-flex justify-content-between align-items-center mt-20">
        <div>
            <!-- <div (click)="setActiveStep(1)" *ngIf="activeStep === 2" class="btn-step">
                < </div> -->
        </div>
        <div>
            <div class="btn more-options" (click)="onShwMoreLess()" *ngIf="numberToSlice === 4 && activeStep === 2">Show more options
            </div>
        </div>
        <div>
            <div (click)="setActiveStep(2)" *ngIf="activeStep === 1" class="btn-step btn-step--next">Next <span>></span>
            </div>
        </div>
    </div>
</nav>