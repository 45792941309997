import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-sub-sidebar-wineries',
  templateUrl: './sub-sidebar-wineries.component.html',
  styleUrls: ['./sub-sidebar-wineries.component.scss']
})
export class SubSidebarWineriesComponent implements OnInit {

  @Input() activePage: string;
  @Input() wineries: Array<any>;
  @Input() choice: string;

  @Output() readonly selected = new EventEmitter();
  @Output() readonly selectedWinery = new EventEmitter();
  @Output() readonly menuOpen = new EventEmitter();

  flavors: Array<any> = this.appService.flavors;
  isMenuOpen: boolean;
  sparkling: boolean;
  loading: boolean;

  infoIndex: number;

  selectedFilter = {
    title: '',
    param: '',
  };

  colors = [
    { title: 'White', isActive: true },
    { title: 'Red', isActive: false },
    { title: 'Pink', isActive: false },
  ];

  selectedColor = 'White';

  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgNameIso: string = 'iso-logo-vine.png' +'?t='+ new Date().getTime();

  asyncResult: any;
  shopType: string;
  choices: string;

  resetSliders = 1;
  minor = 23;

  newMinor() {
    this.minor++;
  }

  newMajor() {
    this.resetSliders = 0;
    this.minor = 0;
  }


  constructor(private appService: AppService) {

  }

  ngOnInit(): void {
    this.urlLogos = this.appService.urlImages;
    this.isMenuOpen = false;

    this.emitValues();
    //console.log("choice", this.choice)
    this.replaceString();
  }

  showInfo(index: number): void {
    if (this.flavors[index].isActive) {
      this.infoIndex = this.infoIndex === index ? undefined : index;
    }
  }

  filter(selectedFilter: any): void {
    this.selectedFilter = selectedFilter;
    this.selectedWinery.emit(this.selectedFilter.param);
  }

  checkFlavor(index: number): void {
    this.flavors[index].isActive = !this.flavors[index].isActive;
    this.flavors[index].value = 0;
    this.emitValues();
  }

  isVisibleFlavor(flavor: any): boolean {
    return (this.sparkling && flavor.isSparkling) || (!this.sparkling && !flavor.isSparkling);
  }

  checkColor(index: number): void {
    this.colors.map(item => item.isActive = false);

    this.colors[index].isActive = true;
    this.selectedColor = this.colors[index].title;
    this.sparkling = false;
    this.emitValues();
  }

  toggleSparkling(): void {
    this.sparkling = !this.sparkling;
    //console.log("Sparkling?", this.sparkling);
    this.emitValues();
  }

  emitValues(): void {
    this.selected.emit({ flavors: this.flavors, color: this.selectedColor, sparkling: this.sparkling });
  }

  open(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuOpen.emit(this.isMenuOpen);
  }

  onTapChange(event, flavor): void {
    this.resetSliders = 1;
    flavor.value = event;
    this.emitValues();
  }

  reset(): void {
    this.flavors.forEach(item => {
      item.value = 0;
    });

    //this.selectedColor = 'White';
    //this.colors.map(item => item.isActive = false);
    //this.colors[0].isActive = true;
    this.sparkling = false;
    this.resetSliders = 0;
    this.emitValues();
  }

async replaceString() {
    this.loading = true;
    this.asyncResult = await this.appService.getType().toPromise();

    this.shopType = this.asyncResult.site_type;

    this.flavors.forEach(res => {

      let infoChoice = res.info;
      let toRplc = "choice";
      let newstr: string;

      switch (this.shopType) {
        case "cider":
          newstr = infoChoice.replace(/choice/gi, "cider");
          res.info = newstr;
          this.loading = false;
          this.choices = "Ciders";
        break;

        case "wine":
          newstr = infoChoice.replace(/choice/gi, "wine");
          res.info = newstr;
          this.loading = false;
          this.choices = "Wines";
        break;
      
        default:
          break;
      }

    });

  }

}
