import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { AppService } from '../../../../app.service';

@Component({
  selector: 'app-rodeo-survey',
  templateUrl: './rodeo-survey.component.html',
  styleUrls: ['./rodeo-survey.component.scss'],
})
export class RodeoSurveyComponent implements OnInit {
  @Output() readonly responseChanged = new EventEmitter();

  surveyForm: FormGroup;

  success: boolean;
  canContact: boolean;

  constructor(private fb: FormBuilder, private appService: AppService) {}

  ngOnInit(): void {
    this.success = false;
    this.canContact = true;

    this.surveyForm = this.fb.group({
      survey_email: ['', [Validators.required, EmailValidators.simple]],
      survey_comments: '',
      survey_name: '',
      can_contact: true,
    });
  }

  onSave(): void {
    this.appService.submitRodeoMailForm(this.surveyForm.value).subscribe(res => {
      this.success = true;
    });
  }

  onCanContact(value: boolean): void {
    this.canContact = value;
    this.surveyForm.controls.can_contact.setValue(value);
  }

  close(): void {
    this.responseChanged.emit();
  }
}
