<div class="container-fluid px-0">
    <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="height: 100vh;">
        <span class="mr-10">Loading</span>
        <i class="d-block fas fa-sync fa-2x fa-spin"></i>
    </div>

    <nav class="navbar navbar-vertical fixed-left navbar-expand-md" id="sidenav-main">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidenav-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <i class="fas fa-bars"></i>
            </span>
          </button>
        <a class="navbar-brand pt-0 text-primary" href="#" target="_blank">
            Wine4Me
          </a>
        <div class="row my-4 px-3">
            <img class="img-fluid" [src]='urlLogos + imgNameMain' alt="" />
        </div>
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <a href="javascript:void(0)">
                            Wine4Me
                  </a>
                    </div>
                    <div class="col-6 collapse-close">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#sidenav-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                    <span></span>
                    <span></span>
                  </button>
                    </div>
                </div>
            </div>
            <form class="mt-4 mb-3 d-md-none">
                <div class="input-group input-group-rounded input-group-merge">
                    <input type="search" class="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <span class="fa fa-search"></span>
                        </div>
                    </div>
                </div>
            </form>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="showScreen('colors')">
                        <i class="fas fa-palette"></i> Colors
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="showScreen('images')">
                        <i class="far fa-images"></i> Images
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="goTo('/home')">
                        <i class="fas fa-home"></i> Home
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    <div class="main-content">
        <!-- Top navbar -->
        <nav class="navbar navbar-tp navbar-expand-md" id="navbar-main">
            <!-- Brand -->
            <span class="h4 mb-0 text-white text-capitalize d-none d-lg-inline-block">Dashboard</span>

            <!-- User -->
            <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="media align-items-center">
                            <span class="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder" src="https://demos.creative-tim.com/argon-dashboard/assets/img/theme/team-1.jpg">
                            </span>
                            <div class="media-body ml-2 d-none d-lg-block">
                                <span class="mb-0 text-sm text-white">Admin</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
        <div class="" *ngIf="!loading">

            <div class="row mx-0 pb-50" *ngIf="screenColors">
                <div class="col-12">
                    <h2 class="mt-25 mb-35 text-center">Select color</h2>
                </div>
                <div class="col-4">
                    <p class="title-set">Set Primary Color:</p>
                    <div class="color-picker-sample">
                        <input class="mt-10" [value]="primaryColor" [(colorPicker)]="primaryColor" />
                    </div>
                    <div [style.background]="primaryColor" class="color-sample"></div>
                </div>
                <div class="col-4">
                    <p class="title-set">Set Secondary Color:</p>
                    <div class="color-picker-sample">
                        <input class="mt-10" [value]="secondaryColor" [(colorPicker)]="secondaryColor" />
                    </div>
                    <div [style.background]="secondaryColor" class="color-sample"></div>
                </div>
                <div class="col-4">
                    <p class="title-set">Set Tertiary Color:</p>
                    <div class="color-picker-sample">
                        <input class="mt-10" [value]="tertiaryColor" [(colorPicker)]="tertiaryColor" />
                    </div>
                    <div [style.background]="tertiaryColor" class="color-sample"></div>
                </div>

                <div class="row mx-0 panel-buttons mt-75 w-100">
                    <div class="col-6 d-flex">
                        <button class="btn btn--submit" (click)="changeTheme(primaryColor, secondaryColor, tertiaryColor)">
                            <i [ngClass]="{ 'fa-save':!loading, 'fa-sync fa-spin':loading}" class="fas mr-5"></i>
                            <span *ngIf="!loading"> {{saveChanges}} </span>
                            <span *ngIf="loading"> Saving changes </span>
                        </button>
                    </div>
                </div>

                <div class="row mx-0 w-100">
                    <div class="col-12">
                        <hr class="divider mt-35" />
                        <h4 class="mt-25 mb-5">Change color preview</h4>
                        <p class="mb-20">Select options to see how is going to be implemented the color change</p>
                        <div class="row mx-0">
                            <div class="col-12 col-md-6 col-lg-3 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('home')" src="./assets/steps/step1.jpg" alt="step1.jpg">
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('main')" src="./assets/steps/step2.jpg" alt="step2.jpg">
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('lateral')" src="./assets/steps/step3.jpg" alt="step3.jpg">
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('detail')" src="./assets/steps/popup.jpg" alt="popup.jpg">
                            </div>
                        </div>
                        <!-- <button (click)="showPreview('home')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="fas fa-home mr-5"></i> Home page
                        </button>
                        <button (click)="showPreview('main')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="fas fa-desktop mr-5"></i> Main pages
                        </button>
                        <button (click)="showPreview('lateral')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="fas fa-bars mr-5"></i> Lateral menu
                        </button>
                        <button (click)="showPreview('detail')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="fas fa-wine-bottle mr-5"></i> Wine detail
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="row mx-0 pb-50" *ngIf="screenImages">
                <div class="col-12">
                    <h1 class="mt-45 mb-35 text-center">Select Images</h1>
                </div>
                <div class="col-12 col-md-4">
                    <div class="image-admin-wrapper">
                        <img *ngIf="imagenActual" class="img-fluid img-preview" [src]="imagenActual" />
                        <img *ngIf="!imagenActual" class="img-fluid img-preview" [src]='urlLogos + imgNameMain' />
                    </div>
                    <div class="image-admin-btn">
                        <input type="file" (change)='onUploadChangeImg($event)' accept='.png, .jpg, .jpeg' id="imagen" class="form-control-file">
                        <button type="button" [disabled]="btnMainDisabled" (click)="updateImg()" class="mt-15 btn btn--submit">Change Main Logo</button>
                        <span class="text-danger text-invalid-format" *ngIf="invalidFormatText != ''"> {{invalidFormatText}} </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="image-admin-wrapper">
                        <img *ngIf="imagenActualIso" class="img-fluid img-preview" [src]="imagenActualIso" />
                        <img *ngIf="!imagenActualIso" class="img-fluid img-preview" [src]='urlLogos + imgNameIso' />
                    </div>
                    <div class="image-admin-btn">
                        <input type="file" (change)='onUploadChangeIsoImg($event)' accept='.png, .jpg, .jpeg' id="imagen" class="form-control-file">
                        <button type="button" [disabled]="btnIsoDisabled" (click)="updateIsologo()" class="mt-15 btn btn--submit">Change Isologo</button>
                        <span class="text-danger text-invalid-format" *ngIf="invalidFormatTextIso != ''"> {{invalidFormatTextIso}} </span>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="image-admin-wrapper">
                        <img *ngIf="imagenActualBg" class="img-fluid img-preview" [src]="imagenActualBg" />
                        <img *ngIf="!imagenActualBg" class="img-fluid img-preview" [src]='urlLogos + imgBgMain' />

                    </div>
                    <div class="image-admin-btn">
                        <input type="file" (change)='onUploadChangeBg($event)' accept='.png, .jpg, .jpeg' id="imagen" class="form-control-file">
                        <button type="button" [disabled]="btnBgDisabled" (click)="updateMainBg()" class="mt-15 btn btn--submit">Change Main Bg</button>
                        <button type="button" [disabled]="false" (click)="updateDefaultBg()" class="mt-15 btn btn--submit">Reset background</button>
                        <span class="text-danger text-invalid-format" *ngIf="invalidFormatTextBg != ''"> {{invalidFormatTextBg}} </span>
                    </div>
                </div>
                <div class="row mx-0 w-100">
                    <div class="col-12">
                        <hr class="divider mt-35" />
                        <h4 class="mt-25 mb-5">Change image preview</h4>
                        <p class="mb-20">Select options to see how is going to be implemented the images change</p>
                        <div class="row mx-0">
                            <div class="col-12 col-md-6 col-lg-4 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('mainLogoHome')" src="./assets/steps/mainLogoHome.jpg" alt="step1.jpg">
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('mainLogoMenu')" src="./assets/steps/mainLogoMenu.jpg" alt="step2.jpg">
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 pl-0">
                                <img class="img-fluid border border-dark img-step" (click)="showPreview('isoLogo')" src="./assets/steps/isologo.jpg" alt="step3.jpg">
                            </div>
                        </div>
                        <!-- <button (click)="showPreview('mainLogoHome')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="far fa-images mr-5"></i> Main logo
                        </button>
                        <button (click)="showPreview('mainLogoMenu')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="fas fa-bars mr-5"></i> Main logo menu
                        </button>
                        <button (click)="showPreview('isoLogo')" type="button" class="btn btn-outline-primary btn--preview">
                            <i class="far fa-image mr-5"></i> Isologo
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>