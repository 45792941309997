import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AppService } from '../app.service';
import Swal from 'sweetalert2'


@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  respuesta: any;
  constructor(private api:AppService, private router: Router ) {
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      

      if(this.api.isLoggedIn){
        return true;
      }
      else{

        Swal.fire({
          title: 'Please, enter you password',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Continue',
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.respuesta = await this.getUser(result.value)
                .subscribe((data) => {
                  //const pass = data[0].pass;

                  if (data == "true") {
                    sessionStorage.setItem('isLog', 'true');
                    this.router.navigate(['/panel'], { queryParamsHandling: 'preserve' });
                  } else {      
                    Swal.fire({
                      title: 'Wrong Password - Try again',
                      showCancelButton: true,
                      confirmButtonText: 'Re-enter password',
                      cancelButtonText: 'Return to Home',
                      showLoaderOnConfirm: true,
                      
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.router.navigate(['/panel'], { queryParamsHandling: 'preserve' });
                      } else {
                        this.router.navigate(['/home'], { queryParamsHandling: 'preserve' });
                      }
                    });
                  }
                }); 
            } catch (error) {
                //console.log(error)
            }
          } else {
            this.router.navigate(['home'], { queryParamsHandling: 'preserve' });
          }
        })
      }
  }

  getUser(psw) {
      return this.api.getUser(psw)
  }
}
