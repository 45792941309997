import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tap-slider',
  templateUrl: './tap-slider.component.html',
  styleUrls: ['./tap-slider.component.scss'],
})
export class TapSliderComponent {
  
  @Input() resetSliders = 0;
  selectedValue = 0;
  @Output() readonly valueChange = new EventEmitter();

  // autoTicks = false;
  max = 10;
  min = 0;
  tickInterval="1";
  thumbLabel = false;
  value: number;

  ngOnChanges(changes: SimpleChanges) {
    if (this.resetSliders == 0) {
      this.value = this.resetSliders;
    }
  }

  //values = [1, 2, 3, 4, 5, 6];

  onSelect(value: number): void {
    this.selectedValue = value;
    this.valueChange.emit(value);
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
}
