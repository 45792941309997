import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() activePage: string;
  @Output() readonly selected = new EventEmitter();

  sideBarLinks: Array<any> = this.appService.sideBarLinks;

  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgNameIso: string = 'iso-logo-vine.png' +'?t='+ new Date().getTime();

  constructor(private appService: AppService) {}

  ngOnInit():void {
    this.urlLogos = this.appService.urlImages;
  }

  select(page: string): void {
    this.selected.emit(page);
  }
}
