import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowseComponent } from './browse/browse.component';
import { DiscoverComponent } from './discover/discover.component';
import { FullMenuComponent } from './fullmenu/fullmenu.component';
import { ExploreComponent } from './explore/explore.component';
import { HomeComponent } from './home/home.component';
import { CustomizationPanelComponent } from './panel/customization-panel/customization-panel.component';

import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthguardGuard } from  './guard/authguard.guard';
import { WineriesComponent } from './wineries/wineries.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'browse', component: BrowseComponent },
  { path: 'explore', component: ExploreComponent },
  { path: 'fullmenu', component: FullMenuComponent },
  { path: 'discover', component: DiscoverComponent },
  { path: 'wineries', component: WineriesComponent },
  { path: 'panel', component: CustomizationPanelComponent, canActivate: [AuthguardGuard] },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    RouterModule.forRoot([
      {path: 'panel', component:CustomizationPanelComponent}
    ],{ onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
