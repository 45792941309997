import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Wine } from '../shared/models/wine.model';
import { AppService } from '../app.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styles: [
    `:root {
      --first-color: primaryColor;
      --second-color: secondaryColor;
      --third-color: tertiaryColor;
    }`
  ]
})
export class ExploreComponent implements OnInit {
  isMenuOpen: boolean;
  noResults: boolean = false;
  noResultsText: string = 'Your search has no results, please try another search';

  wines: Array<Wine> = [];

  activeStep: number;
  isMobile: boolean;

  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;

  colors:any = [];

  loading:boolean = false;

  choice: string;
  choices: string;
  subtitle:string;
  asyncResult: any;
  selectedState: string;

  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgBgMain = 'bg-main.png' +'?t='+ new Date().getTime();

  states:Array<string>;
  wineColor: string;
  flavorSubSidebar:Array<string>;
  isSparkling: string;


  constructor(private appService: AppService,
              private router: Router,
              private api: AppService
              ) {
    this.isMobile = window.innerWidth < 575;
    this.states = this.appService.states;
  }

  ngOnInit(): void {
    this.urlLogos = this.api.urlImages;
    this.changeWords();
  }

  async changeWords(){      
    this.loading = true;
    this.asyncResult = await this.appService.getType().toPromise();

    let shopType = this.asyncResult.site_type;
    this.subtitle = `a ${shopType} by Flavor`;

    switch (shopType) {
      case "cider":
        this.choice = "Cider";
        this.choices = "Ciders";
        this.getTheme();
      break;

      case "wine":
        this.choice = "Wine";
        this.choices = "Wines";
        this.getTheme();
      break;
    
      default:
        break;
    }

}

  onSelectedSidebarOption(path: string): void {
    sessionStorage.removeItem('selectedStyle');
    sessionStorage.removeItem('selectedState');
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

  onSelectedSubSidebarOption(event: any): void {
    if (!sessionStorage.getItem('selectedState')) {
      this.selectedState = 'all';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState')
    }
    this.noResults = false;
    this.loading = true;
    this.wineColor = event.color.toLowerCase();
    this.flavorSubSidebar = event.flavors;
    this.isSparkling = event.sparkling;
    if(sessionStorage.getItem('selectedState') == 'all' || sessionStorage.getItem('selectedState') == 'undefined') {
      this.appService.getWinesByFlavors(event.flavors, event.color, event.sparkling).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else {
      this.appService.getWinesByFlavors(event.flavors, event.color, event.sparkling, sessionStorage.getItem('selectedState')).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    }
  }

  onSelectedMobileOption(event: any): void {
    if (!sessionStorage.getItem('selectedState')) {
      this.selectedState = 'all';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState')
    }
    this.noResults = false;
    this.loading = true;
    this.wineColor = event.color.toLowerCase();
    this.flavorSubSidebar = event.flavors;
    this.isSparkling = event.sparkling;
    if(sessionStorage.getItem('selectedState') == 'all') {
      this.appService.getWinesByFlavors(event.flavors, event.color, event.sparkling).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else {
      this.appService.getWinesByFlavors(event.flavors, event.color, event.sparkling, sessionStorage.getItem('selectedState')).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    }
  }

  
  pickStateHandler(state: any): void {
    this.noResults = false;
    this.loading = true;
    sessionStorage.setItem('selectedState', state);
    this.selectedState = sessionStorage.getItem('selectedState');
    let color:any = this.wineColor.toLowerCase();

    if(state == 'all') {
      this.appService.getWinesByFlavors(this.flavorSubSidebar, color, this.isSparkling).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else {
      this.appService.getWinesByState(sessionStorage.getItem('selectedState'), color).subscribe(res => {
        this.wines = res;
  
        if (res.length == 0) {
          this.noResults = true;
        }
        
        this.loading = false;
      });
    }
  }

  onMenuOpen(isMenuOpen: boolean): void {
    this.isMenuOpen = isMenuOpen;
  }

  onChangeActiveStep(step: number): void {
    this.activeStep = step;
  }

  getTheme() {
    this.loading = true;
    this.appService.getTheme()
      .subscribe(data => {
        this.colors = data;
      
        this.primaryColor = this.colors[0].value;
        this.secondaryColor = this.colors[1].value;
        this.tertiaryColor = this.colors[2].value;

        document.documentElement.style.setProperty('--first-color', this.primaryColor);
        document.documentElement.style.setProperty('--second-color', this.secondaryColor);
        document.documentElement.style.setProperty('--third-color', this.tertiaryColor);
        this.loading = false;
      })
  }

}

