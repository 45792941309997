<div class="modal">
    <div class="modal__wrapper">
        <div class="d-flex justify-content-between w-100">
            <div class="d-md-flex d-block w-100">
                <div class="modal__img" [style.backgroundImage]="'url(' + wine?.image_url + ')'"></div>
                <!-- <div class="modal__img" [style.backgroundImage]="'url(https://vinesleuth.sfo2.cdn.digitaloceanspaces.com/' + wine?.metadata?.image_name + ')'"></div> -->
                <div class="flex-column">
                    <div class="modal__name">{{ wine.name }}</div>
                    <div class="d-flex flex-column">
                        <div class="modal__info border-color">
                            <div class="modal__property flex-column align-items-start" *ngIf="wine?.variety">
                                <div class="modal__label mb-5">Grape Variety</div>
                                <div class="d-flex align-items-center grape-var-wrapper">
                                    <div class="modal__text" *ngFor="let variety of wine?.variety; let i = index">
                                        {{ variety }}<span *ngIf="wine?.variety.length > 1 && i === 0">,&nbsp;</span>
                                    </div>
                                </div>
                                <!-- <p class="modal__text" *ngFor="let variety of wine?.variety; let i = index">
                                    {{ variety }}<span *ngIf="wine?.variety.length > 1 && i === 0">,&nbsp;</span>
                                </p> -->
                            </div>
                            <div class="modal__property flex-column align-items-start" *ngIf="wine?.category">
                                <div class="modal__label mb-5">Category</div>
                                <div class="d-flex align-items-center">
                                    <div class="modal__text">{{ wine?.category }}</div>
                                </div>
                            </div>
                            <div class="modal__property flex-column align-items-start" *ngIf="wine?.vintage">
                                <div class="modal__label mb-5">Vintage</div>
                                <div class="d-flex align-items-center">
                                    <div class="modal__text">{{ wine?.vintage }}</div>
                                </div>
                            </div>
                            <div class="modal__property flex-column align-items-start" *ngIf="wine?.awards">
                                <div class="modal__label mb-5">Awards</div>
                                <div class="d-flex align-items-center">
                                    <div class="modal__text">{{ wine?.awards }}</div>
                                </div>
                            </div>
                            <div class="modal__property flex-column align-items-start" *ngIf="wine?.description">
                                <div class="modal__label mb-5">Description</div>
                                <div class="d-flex align-items-center">
                                    <div class="modal__text">{{ wine?.description }}</div>
                                </div>
                            </div>

                            <!-- <div class="item__property" *ngIf="wine?.metadata?.serving_types">
                                <p class="modal__label mb-5">Price</p>
                                <div class="mb-5" *ngFor="let type of wine?.metadata?.serving_types">
                                    <span class="modal__price">{{ type.price | currency }}</span> <span> / {{ type.type }}</span>
                                </div>
                                <div class="col-12 buy-button t-hidden">
                                    <button type="button" class="btn btn--buy">Buy</button>
                                </div> 
                            </div> -->
                            <div class="item__property" *ngIf="wine?.price">
                                <p class="modal__label mb-5">Price</p>
                                <div class="mb-5">
                                    <span class="modal__price">{{ wine?.price }}</span> <span> </span>
                                </div>
                                <!-- <div class="col-12 buy-button t-hidden px-0">
                                    <a [href]="wine.shop_url" target="_blank" type="button" class="btn btn--buy">Buy</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <svg class="modal__close" (click)="close()" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
          <g style="stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round">
            <path d="m2 2 22 22"/>
            <path d="m24 2-22 22"/>
          </g>
        </svg>
            </div>
        </div>

        <!--Tablet-->
        <div class="row m-hidden" *ngIf="!wine.sparkling">
            <div class="col-12">
                <div class="modal__label mb-15">Flavor</div>

                <div class="row m-hidden m-hidden--fx">
                    <div class="col-6">
                        <div class="d-flex flex-column mb-1" *ngFor="let flavor of flavors | slice: 0:4">
                            <span class="modal__label--info">{{ flavor.title }}</span>
                            <span class="modal__bar">
                                <div class="modal__bar--inner" [style.width]="flavor.percent + '%'"></div>
                            </span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="d-flex flex-column mb-1" *ngFor="let flavor of flavors | slice: 4:8">
                            <span class="modal__label--info">{{ flavor.title }}</span>
                            <span class="modal__bar">
                                <div class="modal__bar--inner" [style.width]="flavor.percent + '%'"></div>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row t-hidden t-hidden--fx">
                    <div class="col-12">
                        <div class="d-flex flex-column" *ngFor="let flavor of flavors">
                            <span class="modal__label--info">{{ flavor.title }}</span>
                            <span class="modal__bar">
                                <div class="modal__bar--inner" [style.width]="flavor.percent + '%'"></div>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 buy-button">
                    <a [href]="wine.shop_url" target="_blank" type="button" class="btn btn--buy">Buy</a>
                </div> -->
            </div>
        </div>

        <!--Mobile-->
        <div class="row t-hidden" *ngIf="!wine.sparkling">
            <div class="col-12">
                <div class="modal__label mb-15">Flavor</div>

                <div class="row">
                    <div class="col-12">
                        <div class="d-flex flex-column" *ngFor="let flavor of flavors">
                            <span class="modal__label--info">{{ flavor.title }}</span>
                            <span class="modal__bar">
                <div class="modal__bar--inner" [style.width]="flavor.percent + '%'"></div>
              </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 buy-button">
                <a [href]="wine.shop_url" target="_blank" type="button" class="btn btn--buy w-100">Buy</a>
            </div> -->
        </div>

        <div class="row" *ngIf="wine.sparkling">
            <div class="col-12">
                <div class="modal__label mb-15">Flavor</div>

                <div class="row">
                    <div class="col-12">
                        <div class="d-flex flex-column" *ngFor="let flavor of sparklingFlavors">
                            <span class="modal__label--info">{{ flavor.title }}</span>
                            <span class="modal__bar">
                <div class="modal__bar--inner" [style.width]="flavor.percent + '%'"></div>
              </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 buy-button">
                <a [href]="wine.shop_url" target="_blank" type="button" class="btn btn--buy">Buy</a>
            </div> -->
        </div>
    </div>
</div>