import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AppService {

  types: Array<any> = [];
  type: string;
  choice: string;
  choices: string;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  flavors = [
    {
      title: 'Sweetness',
      param: 'sweetness',
      info: 'How sweet do you like your choice?',
      floorTitle: 'Dry',
      ceilTitle: 'Sweet',
      isActive: true,
      value: 0,
    },
    {
      title: 'Intensity',
      param: 'body',
      info:'Do you like a wine whose flavor is subtle and nuanced (low in intensity) or do you prefer a bolder, more intense flavor?',
//        'How rich and smooth do you like your choice? A light-bodied choice might feel like skim milk, whereas a full-bodied choice would have more of the consistency of half and half.',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: true,
      value: 0,
    },
    {
      title: 'Fruit',
      param: 'fruit',
      info: 'Think of apples, cherries, berries, peaches… How fruity do you like the smell or taste of your choice?',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: true,
      value: 0,
    },
    {
      title: 'Oak',
      param: 'oak',
      info: 'The smell or taste of wood, vanilla, or caramel.',
      floorTitle: 'Low',
      ceilTitle: 'Heavy',
      isActive: true,
      value: 0,
    },
    /*{
      title: 'Finish',
      param: 'finish',
      info: 'Do you like a choice that comes and goes or one that lingers?',
      floorTitle: 'Short',
      ceilTitle: 'Lingering',
      isActive: true,
      value: 0,
    },*/
    {
      title: 'Acidity',
      param: 'acidity',
      info: 'Think of the fresh and lively sensation from a squirt of lemon. This is acidity and it wakes up your salivary glands.',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: false,
      value: 0,
    },
    {
      title: 'Spice',
      param: 'spice',
      info: 'This could be baking spices, pepper... any kind of exotic spice flavor or smell.',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: false,
      value: 0,
    },
    {
      title: 'Floral',
      param: 'floral',
      info: 'Do you like the smell of fresh flowers such as lavender, orange blossoms or violets in your glass?',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: false,
      value: 0,
    },
    {
      title: 'Minerality',
      param: 'minerality',
      info: 'Think of the smell of fresh rain on a clean sidewalk. Or you could go lick a rock... But who wants to do that?',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: false,
      value: 0,
    },
    {
      title: 'Acidity',
      param: 'acidity',
      info: '',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: true,
      isSparkling: true,
      value: 0,
    },
    {
      title: 'Yeast / brioche',
      param: 'yeasty',
      info: '',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: true,
      isSparkling: true,
      value: 0,
    },
    {
      title: 'Fruity',
      param: 'fruity',
      info: '',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: true,
      isSparkling: true,
      value: 0,
    },
    {
      title: 'Creamy',
      param: 'creamy',
      info: '',
      floorTitle: 'Low',
      ceilTitle: 'High',
      isActive: true,
      isSparkling: true,
      value: 0,
    },
  ];

  sideBarLinks = [
    { title: 'Flavor', activePage: 'Explore', link: 'explore' },
    { title: 'Style', activePage: 'Browse', link: 'browse' },
    { title: 'Winery', activePage: 'Wineries', link: 'wineries' },
    { title: 'Full Menu', activePage: 'FullMenu', link: 'fullmenu' },
    { title: 'Rodeo Uncorked! &reg;', activePage: 'Discover', link: 'discover' },
  ];

  states:Array<string> = [
    "AL",	
    "AK",
    "AZ",
    "AR",	
    "CA",	
    "CO",	
    "CT",
    "DC",	
    "DE",	
    "FL",	
    "GA",	
    "HI",	
    "ID",	
    "IL",	
    "IN",	
    "IA",	
    "KS",	
    "KY",	
    "LA",	
    "ME",	
    "MD",	
    "MA",	
    "MI",	
    "MN",	
    "MS",	
    "MO",	
    "MT",	
    "NE",	
    "NV",	
    "NH",	
    "NJ",	
    "NM",	
    "NY",	
    "NC",	
    "ND",	
    "OH",	
    "OK",	
    "OR",	
    "PA",	
    "RI",	
    "SC",	
    "SD",	
    "TN",	
    "TX",	
    "UT",	
    "VT",	
    "VA",	
    "WA",	
    "WV",	
    "WI",	
    "WY"	
  
  ]

  apiURL:string = environment.apiURL;
  apiURLSpread:string = environment.apiURLSpread
  urlServer: string = environment.urlServer;
  urlImages: string = environment.urlImages;
  clientId: string = environment.clientId;

  loggedInStatus: boolean = false;
  
  constructor(private http: HttpClient) {
  }

  /**
   * 
   * @returns Logged in
   */
   getUser(psw): Observable<any> {

    const body = {
      data: psw
    } 

    let url = this.urlServer + 'getUser.php';
    return this.http.post(url, body);
  }

   setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
  }
   
  get isLoggedIn() {
    if (sessionStorage.getItem('isLog') == 'true') {
      this.loggedInStatus = true;
    }
    //console.log("Status: ", this.loggedInStatus);
    return this.loggedInStatus
  }


  /**
   * Get colors
   * @returns {Observable<any>}
   */
  getTheme(): Observable<any> {
    let url = this.urlServer + 'getColors.php'+'?t='+ new Date().getTime();
    return this.http.get(url);
  }

  /**
   * Get Logos
   * @returns {Observable<any>}
   */
   getImages(): Observable<any> {
    let url = this.urlServer + 'getImages.php';
    return this.http.get(url);
  }
  
  /*getTheme() {
    return this.http.get(this.localUrlTheming);
  }*/

  /**
   * Update Colors
   */
   updateColors(model): Observable<any>  {
     //console.log(model);
    return this.http.post (
      this.urlServer + 'editColors.php', model, this.httpOptions)
  }

  updateMainLogo(model): Observable<any>  {
    //console.log(model);
    return this.http.post (
     this.urlServer + 'editImages.php', model, this.httpOptions)
  }

  updateIsoLogo(model): Observable<any>  {
    //console.log(model);
    return this.http.post (
     this.urlServer + 'editImagesIso.php', model, this.httpOptions)
  }

  updateMainBg(model): Observable<any>  {
    //console.log(model);
    return this.http.post (
     this.urlServer + 'editMainBg.php', model, this.httpOptions)
  }

  updateDefBg(model): Observable<any>  {
    //console.log("this.urlServer", this.urlServer);
    return this.http.post (
    this.urlServer + 'editDefBg.php', model, this.httpOptions)
  }


  /**
   * Get type
   *
   * @date 2020-01-08
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  getType(): Observable<any> {
    return this.http.get(`${this.apiURLSpread}/customer_details?id=${this.clientId}`);
  }

  /**
   * Get wines by flavors
   *
   * @date 2020-01-08
   * @param {*} flavors
   * @param {*} color
   * @param {*} sparkling
   * @returns {Observable<any>}
   * @memberof CompanyService
   */
  getWinesByFlavors(flavors: any, color?: string, sparkling?: string, state?: string): Observable<any> {
    let params = new HttpParams();

    //console.log("state en service: ", state);

    if (color) {
      params = params.append('color', color.toLowerCase());
    }

    if (sparkling) {
      params = params.append('sparkling', sparkling);
    }

    if (state) {
      params = params.append('state', state);
    }

    flavors.forEach(item => {
      if (item.isActive) {
        if (item.isSparkling && sparkling) {
          params = params.append(item.param, item.value.toString());
        } else if (!item.isSparkling && !sparkling) {
          params = params.append(item.param, item.value.toString());
        }
      }
    });


    const limit =  3; //window.innerWidth < 500 ? 3 : 6;
    if (sparkling) {
      return this.http.get(`${this.apiURLSpread}/best_sparkling_wines?id=${this.clientId}&limit=${limit}`, { params });
    } 

    return this.http.get(`${this.apiURLSpread}/best_wines?id=${this.clientId}&limit=${limit}`, { params });
  }
  

  /**
   * Get wines by style
   *
   * @date 2020-01-08
   * @param {string} param
   * @returns {Observable<any>}
   * @memberof apiService
   */
  getWinesByDynamicStyle(param: string, state?: string): Observable<any> {
    const style = encodeURIComponent(param);

    //return this.http.get(`${this.apiURL}/v5/menu/styles/wines?style=${style}`);
    if(state && state != 'all') {
      console.log(`${this.apiURLSpread}/wines_for_varietal?id=${this.clientId}&varietal=${style}&state=${state}`);
      return this.http.get(`${this.apiURLSpread}/wines_for_varietal?id=${this.clientId}&varietal=${style}&state=${state}`);
    } else {
      console.log(`${this.apiURLSpread}/wines_for_varietal?id=${this.clientId}&varietal=${style}`);
      return this.http.get(`${this.apiURLSpread}/wines_for_varietal?id=${this.clientId}&varietal=${style}`);
    }
    
    
  }

  /**
   * Get wines by state
   *
   * @date 2020-01-08
   * @returns {Observable<any>}
   * @memberof ApiService
   */
   getWinesByState(state: string, color?:string): Observable<any> {
    const limit = window.innerWidth < 500 ? 3 : 6;
     if (color) {
      return this.http.get(`${this.apiURLSpread}/best_wines?id=${this.clientId}&state=${state}&color=${color}&limit=${limit}`);
     } else {
      return this.http.get(`${this.apiURLSpread}/best_wines?id=${this.clientId}&state=${state}&limit=200`);
     }
    
  }




    /**
   * Get wines by winery
   *
   * @date 2021-01-10
   * @returns {Observable<any>}
   * @memberof ApiService
   */
    getWinesByWinery(wineryId: string):Observable<any> {
      return this.http.get(`${this.apiURLSpread}/best_wines?id=${this.clientId}&wineryId=${wineryId}&limit=3`);
    }

    /**
     * Get sparkling wines by winery
     *
     * @date 2021-01-10
     * @returns {Observable<any>}
     * @memberof ApiService
     */
    getSparklingWinesByWinery(wineryId: string):Observable<any> {
      return this.http.get(`${this.apiURLSpread}/best_sparkling_wines?id=${this.clientId}&wineryId=${wineryId}&limit=3`);
    }

  /**
   * Get shuffly Array by using
   * The Fisher-Yates algorithm
   * @date 2021-01-10
   * @returns {Array<any>}
   * @memberof ApiService
   */
    shuffleArray(array: Array<any>):Array<any> {
      let newShuffleArray = array;
      for (let i = newShuffleArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = newShuffleArray[i];
        newShuffleArray[i] = newShuffleArray[j];
        newShuffleArray[j] = temp;
      }

      return newShuffleArray;
    }

  /**
   * Get wineries list
   *
   * @date 2021-01-10
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  getWineriesList():Observable<any> {
    return this.http.get(`${this.apiURLSpread}/wineries_list?id=${this.clientId}`);
  }

  /**
   * Get all wines
   *
   * @date 2020-01-08
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  getAllWines(): Observable<any> {
    //return this.http.get(`${this.apiURL}/rodeo/best_wine_lite?limit=200`);
    return this.http.get(`${this.apiURLSpread}/best_wines?id=${this.clientId}&limit=200`);
  }

  /**
   * Get all styles
   *
   * @date 2020-01-08
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  getAllStyles(): Observable<any> {
    //console.log("Get all styles");
    //return this.http.get(`${this.apiURL}/v5/menu/styles`);
    return this.http.get(`${this.apiURLSpread}/wine_varietals_list?id=${this.clientId}`);
    
  }

  /**
   * Submit Rodeo mail
   *
   * @date 2020-01-08
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof ApiService
   */
  submitRodeoMailForm(data: any): Observable<any> {
    return this.http.post(`${this.apiURL}/rodeo/survey`, data);
  }
}
