<div class="modal">
    <div class="modal__wrapper" *ngIf="!success">
        <div class="modal__header mb-30">
            <div class="modal__title">Tell us what you think!</div>

            <svg class="modal__close" (click)="close()" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
        <g style="stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round">
          <path d="m2 2 22 22"/>
          <path d="m24 2-22 22"/>
        </g>
      </svg>
        </div>
        <p class="mb-20">Has this menu helped you find the right choice in the Champion Wine Garden today?</p>

        <form class="form" [formGroup]="surveyForm" novalidate>
            <div class="row">
                <div class="col-12">
                    <textarea name="survey_comments" class="form__textarea" placeholder="Let us know what you think here..." formControlName="survey_comments"></textarea>
                </div>
                <div class="col-12">
                    <input type="text" name="survey_name" class="form__input" placeholder="Name" formControlName="survey_name" />
                </div>
                <div class="col-12">
                    <input type="email" name="survey_email" class="form__input" placeholder="Email" formControlName="survey_email" />
                </div>
                <div class="col-12">
                    <p class="mb-20">Is it okay if we reach back out to you about your experience?</p>

                    <div class="d-flex mb-20">
                        <button class="btn" [class.btn--selected]="canContact" (click)="onCanContact(true)" type="button">Yes</button>
                        <button class="btn" [class.btn--selected]="!canContact" (click)="onCanContact(false)" type="button">No</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button class="btn btn--send" type="submit" (click)="onSave()" [disabled]="surveyForm.invalid">Submit</button>
                </div>
            </div>
        </form>
    </div>

    <div class="modal__wrapper" *ngIf="success">
        <div class="modal__header">
            <div class="modal__title">Thanks for your input!</div>

            <svg class="modal__close" (click)="close()" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
        <g style="stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round">
          <path d="m2 2 22 22"/>
          <path d="m24 2-22 22"/>
        </g>
      </svg>
        </div>

        <div class="mt-30">
            <img src="assets/logos/iso-logo-vine.png" />
        </div>
    </div>
</div>