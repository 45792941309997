import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ItemComponent } from './components/item/item.component';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { RodeoSurveyComponent } from './components/modal/rodeo-survey/rodeo-survey.component';
import { WineModalComponent } from './components/modal/wine/wine-modal.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SubSidebarComponent } from './components/sub-sidebar/sub-sidebar.component';
import { MobileFilterComponent } from './components/mobile-filter/mobile-filter.component';
import { TapSliderComponent } from './components/tap-slider/tap-slider.component';
import { MatSliderModule } from '@angular/material/slider';
import { ColorPickerModule } from 'ngx-color-picker';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    ColorPickerModule
  ],
  declarations: [
    FooterComponent,
    SidebarComponent,
    SubSidebarComponent,
    ItemComponent,
    WineModalComponent,
    NotFoundComponent,
    HeaderComponent,
    MobileHeaderComponent,
    MobileFilterComponent,
    RodeoSurveyComponent,
    TapSliderComponent
  ],
  providers: [],
  exports: [
    FooterComponent,
    SidebarComponent,
    SubSidebarComponent,
    ItemComponent,
    WineModalComponent,
    NotFoundComponent,
    HeaderComponent,
    MobileFilterComponent,
    MobileHeaderComponent,
    RodeoSurveyComponent,
    TapSliderComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
