import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

// declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  title:string = 'Wine4Me';

  constructor(
    public router: Router,
    private titleService: Title, 
    private metaService: Meta
    ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // TODO enable for production
        // gtag('config', 'UA-43086333-1', { 'page_path': event.urlAfterRedirects });
      }
    });
  }

  ngOnInit(): void {
    this.setUpAnalytics();
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'robots', content: 'noindex, nofollow'}
    ]);
        
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });
    
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
          gtag('config', 'UA-43086333-1',
              {
                  page_path: event.urlAfterRedirects
              }
          );
      });
  }

}
