import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  appService: AppService;
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.appService = this.injector.get(AppService);

    const newReq = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'X-API-TOKEN': '9080b822164ce4980fe488e9dc8f3f14',
      },
    });

    return next.handle(newReq);
  }
}
