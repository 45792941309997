import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Wine } from '../shared/models/wine.model';
import { AppService } from '../app.service';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
})
export class BrowseComponent implements OnInit {
  isMenuOpen: boolean;
  noResults: boolean = false;
  noResultsText: string = 'Your search has no results, please try another search';

  loading: boolean = false;

  wines: Array<Wine> = [];
  types: Array<any> = [];

  choice: string;
  choices: string;
  subtitle:string;
  asyncResult: any;
  selectedState: string;
  selectedStyle: string;

  urlLogos: string;
  imgNameMain = 'logo-vine.png' +'?t='+ new Date().getTime();
  imgBgMain = 'bg-main.png' +'?t='+ new Date().getTime();

  states:Array<string>;
  wineColor: string;
  flavorSubSidebar:Array<string>;
  isSparkling: string;

  constructor(
    private appService: AppService,
    private router: Router,
    private api: AppService
    ) {
      this.states = this.appService.states;
  }

  ngOnInit(): void {
    sessionStorage.removeItem('selectedStyle');
    if (!sessionStorage.getItem('selectedState')) {
      this.selectedState = 'all';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState')
    }
    this.urlLogos = this.api.urlImages;

    if(!sessionStorage.getItem('selectedStyle') && this.selectedState != 'all') {
      this.appService.getWinesByState(this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else {
      this.getAllWines();
    }
    this.getAllStyles();
    this.changeWords();
  }

  async changeWords(){      
    this.loading = true;
    this.asyncResult = await this.appService.getType().toPromise();

    let shopType = this.asyncResult.site_type;

    switch (shopType) {
      case "cider":
        this.choice = "Cider";
        this.choices = "Ciders";
        this.subtitle = `${this.choices} by Style`;
      break;

      case "wine":
        this.choice = "Wine";
        this.choices = "Wines";
        this.subtitle = `${this.choices} by Style`;
      break;
    
      default:
        break;
    }

}

  getAllStyles(): void {
    this.appService.getAllStyles().subscribe(
      res => {
        this.types = [];
        res.varietals.forEach(item => {
          this.types.push({ title: item, param: item });
        });
      });
  }

  getAllWines(): void {
    this.noResults = false;
    this.loading = true;
    this.appService.getAllWines().subscribe(res => {
      this.wines = res;
      if (res.length == 0) {
        this.noResults = true;
      }
      this.loading = false;
    });
  }

  onSelectedSidebarOption(path: string): void {
    sessionStorage.removeItem('selectedStyle');
    sessionStorage.removeItem('selectedState');
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

  onSelectedSubSidebarOption(param: string): void {
    this.noResults = false;
    if (!sessionStorage.getItem('selectedState')) {
      this.selectedState = 'all';
    } else {
      this.selectedState = sessionStorage.getItem('selectedState')
    }
    this.loading = true;
    sessionStorage.setItem('selectedStyle', param);
    this.selectedStyle = sessionStorage.getItem('selectedStyle');

    if(this.selectedState == 'all') {
      this.appService.getWinesByDynamicStyle(this.selectedStyle).subscribe(res => {
        this.wines = res;
        this.loading = false;
      });
    } else {
      this.appService.getWinesByDynamicStyle(this.selectedStyle, this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    }
  }

  pickStateHandler(state: any): void {
    this.noResults = false;
    this.loading = true;
    sessionStorage.setItem('selectedState', state);
    this.selectedState = sessionStorage.getItem('selectedState');

    if(!sessionStorage.getItem('selectedStyle') && this.selectedState != 'all') {
      this.appService.getWinesByState(this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    } else if(!sessionStorage.getItem('selectedStyle') && this.selectedState == 'all') {
      this.getAllWines();
    } else {
      this.appService.getWinesByDynamicStyle(this.selectedStyle, this.selectedState).subscribe(res => {
        this.wines = res;
        if (res.length == 0) {
          this.noResults = true;
        }
        this.loading = false;
      });
    }
  }

  onMenuOpen(isMenuOpen: boolean): void {
    this.isMenuOpen = isMenuOpen;
  }
}
